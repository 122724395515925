.SearchAround {
  $paddingMobile: 50px 20px;
  $paddingDesktop: 68px 50px;
  $mobileGap: 20px;
  $desktopGap: 180px;

  padding: $paddingMobile;
  display: flex;
  flex-direction: column;
  gap: $mobileGap;
  background: $orange;

  &__title {
    font-family: $mainFont;
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
  }

  &__suggestions {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    .ButtonV2 {
      padding: 11px 12px;
      background: #ffffff66;
      border: 1px solid #ffffff;
      color: $black;
    }
  }

  @media screen and (min-width: 768px) {
    padding: $paddingDesktop;
    flex-direction: row;
    align-items: center;
    gap: $desktopGap;

    &__title {
      font-size: 22px;
      line-height: 32px;
    }
  }
}