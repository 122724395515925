.TextStoreItemComp {
  font-family: $mainFont;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  color: $black;

  @media screen and (min-width: 768px) {
    font-size: 14px;
    line-height: 24px;
  }
}