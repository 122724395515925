.meteo {
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;

  .weather {
    margin: 10px 20px 0 0;
    position: relative;
    font-size: 19px;
    z-index: 1;
  }

  .temperature {
    margin: 10px 20px 0 0;
    position: relative;
    font-size: 14px;
    z-index: 1;

    img {
      bottom: -20px;
      top: initial;
      right: 10px;
      left: initial;
      width: 100px;
    }
  }

  img {
    width: 120px;
    position: absolute;
    top: 20px;
    left: -20px;
  }

  @media screen and (min-width: 768px) {
    .weather {
      font-size: 24px;
      margin: 10px 20px 0 0;
    }

    .temperature {
      font-size: 19px;
    }

    img {
      top: 20px;
      width: 200px;
      left: -200px;
    }
  }
}