.DescriptionShopV2 {
  $paddingMobile: 50px 40px;
  $paddingDesktop: 80px 50px 50px 50px;
  $gap: 30px;

  padding: $paddingMobile;
  display: flex;
  flex-direction: column;
  gap: $gap;
  background: $orange;

  .StickyItem {
    justify-content: flex-start;
  }

  &__title {
    display: none;
  }

  &__description {
    font-family: $mainFont;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    color: $black;
    white-space: pre-wrap;
  }

  &__langs {
    display: flex;
    align-items: center;
    justify-content: stretch;
    flex-wrap: wrap;
    gap: 20px;

    &__button {
      flex: 1;
      min-width: 120px;
      flex-direction: row-reverse;
      gap: 10px;

      &--selected {
        text-decoration: underline;
      }

      .DescriptionShopV2__langs__flag {
        $flagSize: 24px;

        width: $flagSize;
        height: $flagSize;
        min-width: $flagSize;
        min-height: $flagSize;
        border-radius: 100px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 132.5% !important;
          height: 132.5% !important;
          max-width: none !important;
          max-height: none !important;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    padding: $paddingDesktop;

    &__title {
      display: block;
      font-family: $mainFont;
      font-size: 32px;
      line-height: 40px;
      font-weight: 700;
      text-transform: none;
    }

    &__langs {
      gap: 30px;

      .ButtonV2 {
        .DescriptionShopV2__langs__flag {
          $flagSize: 30px;

          width: $flagSize;
          height: $flagSize;
          min-width: $flagSize;
          min-height: $flagSize;
        }
      }
    }
  }
}