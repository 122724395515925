.Quote {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 30px 30px;

  .content {
    width: 740px;
    max-width: 100%;
    text-align: center;
    font-style: italic;
    font-size: 16px;
    line-height: 30px;
    margin: 20px 0;
  }

  .legend {
    text-align: center;
    font-size: 12px;
  }

  @media screen and (min-width: 768px) {
    padding: 50px;

    .content {
      font-size: 24px;
      line-height: 40px;

    }
  }
}
