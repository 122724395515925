.bannerImage {
  width: 100%;
  display: block;
  position: relative;

  img {
    width: 100%;
    object-fit: cover;
  }
}
