.DetailsStoreItemComp {
  $gap: 30px;

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $gap;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;

    .DetailShopV2 {
      width: calc(50% - #{$gap / 2});
    }
  }
}
