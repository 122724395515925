.HeroShopV2 {
  $padding: 20px;
  $gap: 10px;

  background: $white;

  .ImagesCarousel {
    img {
      aspect-ratio: 360 / 340;
      object-fit: cover;
    }
  }

  .LabelsShopV2 {
    padding: 10px $padding $padding $padding;
  }

  .HeroShopV2__buttonsContainer {
    flex: 1;
    padding: 10px $padding $padding $padding;
    display: flex;
    flex-direction: column;
    gap: $gap;

    .ButtonV2 {
      flex: 1;
      flex-wrap: wrap;
    }

    .HeroShopV2__primaryButtons {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: $gap;
    }

    .HeroShopV2__secondaryButtons {
      flex: 1;
      display: flex;
      align-items: center;
      gap: $gap;
      flex-wrap: wrap;
    }
  }

  .ManagementStoreV2 {
    margin-top: 20px;
  }

  @media screen and (min-width: 768px) {
  }
}