$cardSmallHeight: 270px;
$cardMediumHeight: 315px;
$cardLargeHeight: 360px;

.eventsSection {
  display: flex;
  justify-content: center;
  padding: 3rem;
  @media all and (min-width: 1600px) {
    padding: 4rem;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    max-width: 1400px;
    @media (min-width: 1600px) {
      max-width: 1600px;
    }

    h2 {
      font-weight: 700;
      text-align: center;
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 1.8rem;
      @media all and (max-width: 767px) {
        font-size: 1em;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 1rem;
    justify-content: center;

    > * {
      grid-column: span 2;
      transition: transform 0.3s ease;

      &:nth-child(5n + 1) {
        grid-column: 2 / span 2;
      }

      &:nth-child(-n + 5) {
        grid-row: 1;
      }

      &:nth-child(n + 6) {
        grid-row: 2;
      }

      &:nth-child(1),
      &:nth-child(5),
      &:nth-child(8) {
        height: $cardLargeHeight;
        @media all and (min-width: 1600px) {
          height: $cardLargeHeight * 1.3;
        }
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(10) {
        height: $cardSmallHeight;
        @media all and (min-width: 1600px) {
          height: $cardSmallHeight * 1.3;
        }
      }

      &:nth-child(2),
      &:nth-child(9) {
        height: $cardMediumHeight;
        @media all and (min-width: 1600px) {
          height: $cardMediumHeight * 1.3;
        }
      }
    }

    &.line {
      grid-template-rows: 1fr;
    }

    .card {
      min-height: 200px;
      background-size: cover;
      background-position: center;
      display: flex;
      align-items: flex-end;
      padding: 1rem;
      gap: 10px;
      position: relative;
      z-index: 1;
      transform-origin: center center;
      overflow: hidden;

      &:not(:nth-child(2), :nth-child(4)) {
        margin: 20px 0;
      }

      /** Specific margin for the second and fourth cards */
      &:nth-child(2),
      &:nth-child(4) {
        margin: 40px 0;
      }

      .cardTitle,
      p {
        color: white;
        z-index: 4;
        position: relative;
      }

      .cardTitle {
        text-transform: uppercase;
        font-size: 0.9rem;
        font-weight: 700;
      }

      p {
        font-size: 1rem;
        font-weight: 500;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: inherit;
        background-size: cover;
        background-position: center;
        transition: transform 0.3s ease;
        background-color: rgba(0, 0, 0, 0.2);
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 0, 0, 0.3);
        opacity: 0;
        transition: opacity 0.8s ease;
        pointer-events: none;
      }

      &:hover {
        cursor: pointer;

        &::before {
          transform: scale(1.05);
        }

        z-index: 2;

        .overlay {
          opacity: 1;
          z-index: 3;
        }
      }
    }

    @media (min-width: 1024px) {
      .card .content {
        opacity: 0;
        transition: opacity 0.3s ease;
      }

      .card:hover .content {
        opacity: 1;
      }
    }
  }

  @media (max-width: 768px) {
    .grid > * {
      transform: none !important;
    }
    padding: 30px 0 30px 30px;
  }
}

@media (max-width: 1024px) {
  .eventsSection {
    .embla {
      overflow: hidden;
      width: 100%;

      &__container {
        display: flex;
        gap: 1rem;

        .embla__slide {
          flex: 0 0 auto;
          min-width: 0;

          .card {
            width: 270px;
            height: 270px;
            background-size: cover;
            margin: 0 !important;
            border-radius: 5px;
            display: flex;
            flex-flow: column;
            justify-content: end;
            padding: 1rem;
            align-items: flex-start;
            text-align: left;

            h3,
            p {
              color: white;
              z-index: 4;
              position: relative;
            }

            h3 {
              text-transform: uppercase;
              font-weight: 700;
              font-size: 1rem;
            }

            p {
              font-size: 1.1rem;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

.card-link {
  display: block;
  text-decoration: none;
  color: inherit;
}
