.MainInfosShopV2 {
  $gap: 30px;

  display: flex;
  flex-direction: column;
  gap: $gap;
  container-type: inline-size;

  .MainInfosShopV2__details {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $gap;
  }

  .MainInfosShopV2__map {
    width: 100%;
    aspect-ratio: 280 / 220;
    border-radius: 3px;
    overflow: hidden;
  }

  @container (min-width: 360px) {
    .MainInfosShopV2__details {
      flex-direction: row;
      flex-wrap: wrap;

      .DetailShopV2 {
        width: calc(50% - #{$gap / 2});
      }
    }
  }
}