$green: #D0DB5F;
$opacity-green: #D0DB5F80;

.submit-button {
  background-color: $green;
}

.custom-confirm-button {
  background-color: $green !important;
  color: white !important; 
  border: none !important;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
}

.custom-confirm-button:hover {
  background-color: $opacity-green;
}