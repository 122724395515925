.NewFavoritesGroupButton {
  width: 100%;

  .NewFavoritesGroupButton__button {
    width: 100%;
    max-width: 320px;
    margin: 0 auto;

    svg {
      width: 10px;
      height: 10px;
      min-width: 10px;
      min-height: 10px;
    }
  }

  @media screen and (min-width: 768px) {
    .NewFavoritesGroupButton__button {
      gap: 10px;

      svg {
        width: 14px;
        height: 14px;
        min-width: 14px;
        min-height: 14px;
      }
    }
  }
}