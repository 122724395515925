.partnerList {
  overflow: hidden;
  padding: 10px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 10px;

  .header {
    font-size: 25px;
    line-height: 30px;
    font-weight: 700;
    margin: 0 0 10px;
    text-transform: uppercase;
    text-align: center;
  }

  .scrollBlock {
    display: flex;
    width: 100%;
    overflow: auto;

    .block {
      width: 164px;
      min-width: 164px;
      height: 100px;
      max-height: 100px;
      cursor: pointer;
      margin: 0 10px;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  @media screen and (min-width: 768px) {
    margin: 40px 0 10px;

    .header {
      font-size: 34px;
      line-height: 43px;
      margin: 0 0 30px;
    }

    .scrollBlock {
      width: initial;
      max-width: 100%;
    }
  }
}
