.ShopV2Card {
  $padding: 20px;
  $containerGap: 10px;
  $rowGap: 6px;
  $columnGap: 15px;

  background: $white;

  img {
    aspect-ratio: 360 / 340;
    object-fit: cover;
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding: $padding;
    gap: $containerGap;

    .StickyItem {
      justify-content: flex-start;
    }

    &__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-transform: none;
    }

    &__details {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: $rowGap;
      column-gap: $columnGap;
    }
  }

  @media screen and (min-width: 768px) {
  }
}