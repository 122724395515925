@media all and (min-width: 375px) {
  .InteractiveBanner{
    a{
      padding: 10px 15px;
      margin: 0;
    }
  }
}

@media all and (min-width: 1440px) {
  .InteractiveBanner{
    a{
      padding: 1rem 1.3rem;
      margin: 0;
    }
  }
}
