.portraitPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 160px 0 50px;
  margin: auto;
  max-width: 1064px;

  .breadcrumb {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.3;
    font-size: 13px;

    svg {
      height: 9px;
      margin: 0 5px;
    }
  }

  .title {
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: 0.7px;
    font-weight: 700;
    text-align: center;
    margin-top: 20px;
  }

  .description {
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    margin: 20px 10px;
  }

  .image {
    width: 100%;
    position: relative;
    margin: 0 auto 70px;
    max-height: 600px;

    img {
      max-height: inherit;
      max-width: inherit;
      object-fit: contain;
    }

    .quote {
      position: absolute;
      bottom: -70px;
      right: 60px;
      left: 60px;
      margin: auto;
      background: $pro;
      color: $white;
      font-size: 16px;
      font-style: italic;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      text-align: center;

      svg {
        width: 30px;
        margin-bottom: 10px;

        path {fill: $white;}
      }
    }
  }

  .qna {
    padding: 0 27px;

    &>div {
      margin: 20px 0;
    }

    .question {
      font-size: 16px;
      font-weight: 700;
      line-height: 25px;
      margin-bottom: 5px;

      span { color: $pro; }
    }

    .answer {
      font-size: 12px;
      line-height: 25px;
    }
  }

  .Button {
    margin: 10px auto;
  }

  @media screen and (min-width: 768px) {
    padding: 185px 0 50px;

    .title {
      font-size: 40px;
    }

    .description {
      font-size: 17px;
      font-weight: 700;
      line-height: 30px;
      max-width: 80%;
      text-align: center;
      margin: 20px auto;
    }

    .image {
      margin-bottom: 40px;

      .quote {
        bottom: -40px;
        right: 5%;
        left: initial;
        font-size: 21px;
        padding: 25px;
        max-width: 350px;

        svg {
          width: 43px;
          margin: 15px;
        }
      }
    }

    .qna {
      padding: 0 110px;
    }
  }
}

.SSRApp:has(.portraitPage) {
  .Header {
    background: $pro;
  }
}