.miniCarousel {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 20px 0 10px;

  .header {
    font-size: 66px;
    font-family: $titleFont;
    line-height: 32px;
    margin: 10px 25px;

    span {
      display: block;
      font-family: $mainFont;
      font-weight: 700;
      font-size: 25px;
      text-transform: uppercase;
      color: $pro;
    }
  }

  .scrollBlock {
    display: flex;
    width: 100%;
    overflow: auto;
    padding: 0 25px;

    .block {
      width: 159px;
      min-width: 159px;
      height: 168px;
      background-size: cover;
      display: flex;
      justify-content: flex-start;
      align-items: end;
      color: $white;
      padding: 11px 15px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      margin: 5px;
      cursor: pointer;
    }
  }

  @media screen and (min-width: 768px) {
    margin: 60px 85px;
    flex-direction: row;
    align-items: center;

    .header {
      font-size: 73px;
      line-height: 36px;
      text-align: center;
      max-width: 200px;
      margin: 0 20px 0;

      span {
        font-size: 28px;
      }
    }

    .scrollBlock {
      flex-wrap: wrap;
      justify-content: space-evenly;
      padding: 0;

      .block {
        margin: 5px 0;
      }
    }
  }
}