.tilesBlock {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 20px 0 10px;

  .header {
    font-size: 66px;
    font-family: $titleFont;
    line-height: 32px;
    margin: 10px 25px;

    span {
      display: block;
      font-family: $mainFont;
      font-weight: 700;
      font-size: 25px;
      text-transform: uppercase;
      color: $pro;
    }
  }

  .scrollBlock {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 0 25px;
    gap: 10px;

    .blockText {
      width: 100%;
      background-color: $black60;
      padding:10px;
    }

    .tile {
      width: 94px;
      min-width: 94px;
      height: 100px;
      background-size: cover;
      display: flex;
      justify-content: flex-start;
      align-items: end;
      color: $white;
      text-transform: uppercase;
      font-weight: 700;
      line-height: 18px;
      padding: 0;
      font-size: 12px;
      cursor: pointer;
    }
  }

  @media screen and (min-width: 768px) {
    margin: 60px 85px;
    align-items: center;

    .header {
      font-size: 73px;
      line-height: 36px;
      text-align: center;

      span {
        font-size: 28px;
        display: inline;
        margin-left: 10px;
      }
    }

    .scrollBlock {
      padding: 0;
      gap: 20px;

      .tile {
        width: 288px;
        min-width: 288px;
        height: 294px;
        font-size: 16px;
      }
    }
  }
}