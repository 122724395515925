.DefaultCarousel {
  .DefaultCarousel__items {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    row-gap: 30px;
    column-gap: 20px;
    width: fit-content;
  }

  .DefaultCarousel__carousel {
    position: relative;

    .swiper,
    .swiper-wrapper {
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }

    .swiper-slide {
      height: auto;
    }

    .DefaultCarousel__carousel__pagination {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 30px;

      .DefaultCarousel__carousel__pagination__button {
        width: 10px;
        height: 10px;
        scale: 0.8;
        background: $black;
        opacity: 0.4;
        border-radius: 100%;
        transition: all 0.2s ease-in-out;

        &.DefaultCarousel__carousel__pagination__button--active {
          opacity: 1;
          scale: 1;
        }
      }
    }

    .DefaultCarousel__carousel__navigationButton {
      display: none;
    }
  }

  @media screen and (min-width: 768px) {
    .DefaultCarousel__carousel {
      .DefaultCarousel__carousel__navigationButton {
        position: absolute;
        top: 50%;
        translate: 0 -50%;
        z-index: 1;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: $white;
        box-shadow: 0 2px 3px 0 #00000026;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          background: $gray100;
        }

        svg {
          width: 14px;

          path,
          line {
            stroke: $black;
          }
        }

        &.DefaultCarousel__carousel__navigationPrev {
          left: 0;
          translate: -50% -50%;
        }

        &.DefaultCarousel__carousel__navigationNext {
          right: 0;
          translate: 50% -50%;
        }
      }
    }
  }
}