.ShareModal {
  $mobilePadding: 0 20px 40px 20px;
  $desktopPadding: 0 20px 50px 20px;

  .ShareModal__container {
    width: 100%;
    padding: $mobilePadding;
    max-width: 660px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .ShareModal__container__items {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      flex-wrap: wrap;

      .ShareModal__container__items__item {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        padding: 5px;

        &:hover {
          svg {
            background: #21212B1A;
          }
        }

        svg {
          padding: 10px;
          width: 40px;
          height: 40px;
          min-width: 40px;
          min-height: 40px;
          border-radius: 100px;
          background: $white;
          transition: all 0.2s;
        }

        .ButtonV2__text {
          font-weight: 500;
          white-space: nowrap;
        }
      }
    }

    .ShareModal__container__copyContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .ShareModal__container__copyContainer__input {
        width: 100%;

        .ButtonV2 {
          background: $white;
          flex-wrap: nowrap;

          .ButtonV2__text {
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-weight: 500;
            color: #21212B5A;
          }
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    .ShareModal__container {
      padding: $desktopPadding;
      gap: 40px;

      .ShareModal__container__items {
        gap: 20px;

        .ShareModal__container__items__item {
          svg {
            padding: 15px;
            width: 54px;
            height: 54px;
            min-width: 54px;
            min-height: 54px;
          }
        }
      }
    }
  }
}