.imageCarouselPopup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000000bf;
  z-index: 1001;
  display: flex;

  .close {
    position: absolute;
    top: 3%;
    right: 3%;
    padding: 10px;
    background: $pro;
    border-radius: 20px;

    svg {
      width: 15px;
      height: 15px;

      path {
        stroke: $white;
      }
    }
  }

  .scrollBlock {
    width: 100%;
    margin: auto;
    position: relative;
    height: auto;

    .scroller {
      display: flex;
      overflow: auto;
      height: 100%;
      scroll-snap-type: x mandatory;

      .imageSlide {
        min-width: 100vw;
        margin: auto;
        height: 100%;
        scroll-snap-align: center;
        position: relative;

        img {
          width: 100%;
          object-fit: contain;
          height: 100%;
          max-height: 80vh;
        }

        .blockText {
          background: #00000090;
          color: white;
          text-align: center;
          padding: 0 10px 30px;

          .title {
            font-size: 25px;
            line-height: 40px;
            font-weight: bold;
            margin-bottom: 5px;
          }
          .description {
            font-size: 16px;
          }
          .link {
            position: absolute;
            right: 10px;
            bottom: 5px;
            display: flex;
            align-items: center;

            svg {
              max-height: 12px;
              path, circle{ stroke: $white;}
            }
            span {
              margin-left: 5px;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .pageButton {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: white;
    height: 44px;
    width: 44px;
    border-radius: 22px;
    align-items: center;
    justify-content: center;
    &.previous {
      left: 1%;
    }
    &.next {
      right: 1%;
    }
  }

  .pagination {
    display: flex;
    position: absolute;
    bottom: -40px;
    left: 0;
    right: 0;
    margin: auto;
    justify-content: center;

    button {
      width: 20px;
      height: 20px;
      border: solid 2px $white;
      border-radius: 10px;
      margin: 0 10px;

      &.current {
        background: $white;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .scrollBlock .scroller .imageSlide {
        min-width: 90vw;
        margin: auto 5vw;

      .blockText {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0 150px 15px;
      }
    }

    .pageButton {
      display: flex;
    }
  }
}