.ArticleHero {
  $mobilePadding: 0;
  $tabletPadding: 270px 10% 0 10%;
  $desktopPadding: 270px 20% 0 20%;
  $mobileContainerPadding: 50px 20px 40px 20px;
  $desktopContainerPadding: 67px 10%;

  padding: $mobilePadding;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__copyright {

    top: 20px;

    @media all and (min-width: 1440px) {
      bottom: 120px;
      top: unset;
    }

    .copyright-symbol {
      width: 30px;
    }
  }

  .ArticleHero__image {
    width: 100%;
    aspect-ratio: 360 / 340;
    object-fit: cover;
  }

  .ArticleHero__container {
    background: $blue;
    padding: $mobileContainerPadding;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    text-align: center;
    font-family: $mainFont;

    h1 {
      font-size: 28px;
      line-height: 38px;
      font-weight: 700;
      text-transform: none;
    }

    h2 {
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
    }

    .ArticleHero__container__buttonsContainer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
      max-width: 320px;

      .ShareButton,
      .FavoriteButton {
        flex: 1;
        background: #FFFFFF66;
        border: 1px solid $white;

        &:hover {
          background: #FFFFFF99;
        }

        .ButtonV2__text {
          word-break: unset;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    padding: $tabletPadding;

    .ArticleHero__image {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      max-height: calc(100% - 90px);
      aspect-ratio: auto;
    }

    .ArticleHero__container {
      z-index: 1;
      padding: $desktopContainerPadding;
      border-radius: 3px;

      h1 {
        font-size: 42px;
        line-height: 54px;
      }

      h2 {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    padding: $desktopPadding;
  }
}
