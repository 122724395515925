.DetailShopV2 {
  $headerGap: 6px;
  $gap: 10px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $gap;

  .DetailShopV2__header {
    display: flex;
    gap: $headerGap;
    color: #4d4d55;

    .DetailShopV2__title {
      color: #4d4d55;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
    }

    .DetailShopV2__header__iconContainer {
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 16px;
        height: 16px;

        &.withStroke {
          path {
            stroke: currentColor;
          }
        }

        &.envelopeV2,
        &.facebookV2,
        &.instagramV2,
        &.youtubeV2 {
          path {
            fill: none;
          }

          rect,
          path {
            stroke: currentColor;
          }
        }

        &.facebookV2 {
          path {
            &:nth-child(1) {
              fill: currentColor;
            }
          }
        }

        &.youtubeV2 {
          path {
            &:nth-child(2) {
              fill: currentColor;
            }
          }
        }

        path {
          fill: currentColor;
        }
      }
    }
  }

  .DetailShopV2__value,
  .DetailShopV2__value .ButtonV2__text {
    color: $black;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    white-space: wrap;
    word-break: break-word;
  }

  @media screen and (min-width: 768px) {
    .DetailShopV2__header {
      .DetailShopV2__title {
        font-size: 14px;
        line-height: 24px;
      }

      .DetailShopV2__header__iconContainer {
        height: 24px;
      }
    }
  }
}