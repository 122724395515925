.icon--list {
  padding: 10px 20px;
  border: 1px solid rgba(33, 33, 43, 0.2);
  border-radius: 3px;
  margin: 4px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  &--title {
    font-size: 13px;
    line-height: 20px;
    font-weight: 700;
    font-family: var(--mainFont, Gotham, Arial, sans-serif);
  }

  &--icons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 8px;
    flex: 1;
  }

  a {
    cursor: pointer;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  @media screen and (min-width: 768px) {
    padding: 11px 15px;

    &--title {
      font-size: 14px;
    }
  }
}
