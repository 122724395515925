@use "sass:map";

.searchBar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .inputBlock {
    position: relative;
    margin: 8px 15px;
    width: 400px;
    max-width: calc(100% - 104px);

    input {
      background: $white;
      padding: 12px 20px 12px 30px;
      border-radius: 20px;
      color: $black;
      width: 100%;
      border: solid 1px $black;
      height: 41px;
    }

    svg {
      position: absolute;
      left: 10px;
      top: 11px;
    }

    .predictions {
      position: absolute;
      top: 40px;
      left: 18px;
      right: 18px;
      background: $white;
      color: $black;
      border: solid 1px $client;
      z-index: 1;
      border-radius: 0 0 5px 5px;

      button {
        padding: 7px 5px;
        opacity: 0.7;
        width: 100%;
        text-align: left;
        border-top: solid 1px $gray300;
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: space-between;

        &:first-child {
          border-top: none;
        }

        svg {
          position: initial;
          top: initial;
          left: initial;
          margin-right: 5px;
          width: 10px;
          min-width: 10px;
          height: 14px;
          min-height: 14px;

          path {
            fill: $gray300;
          }
        }

        &:hover, &:focus {
          opacity: 1;
          background: $gray100;

          svg path {
            fill: $client;
          }
        }
      }

      .prediction {
        display: flex;
        align-items: center;
      }

      .tag {
        display: flex;
        background: red;
        border-radius: 30px;
        padding: 4px 8px;
        align-items: center;
        font-weight: bold;
        font-size: 12px;

        svg {
          min-height: 12px;
          min-width: 12px;
        }

        @each $type, $content in $searchColors {
          &.#{$type} {
            background: map.get($content, background);
            color: map.get($content, color);

            svg path {
              fill: map.get($content, color);
            }
          }
        }
      }
    }
  }

  .validate {
    height: 44px;
    width: 44px;
    padding: 0;
    margin-right: 30px;
  }

  &.hero {
    .inputBlock {
      max-width: 100%;
    }

    input {
      border: none;
    }

    .validate {
      height: initial;
      width: initial;
      padding: 12px 35px;
      margin-right: 0;
    }
  }

  .filters {
    display: flex;
    margin: 8px 0;

    .categoryButton, .mapButton {
      display: flex;
      align-items: center;
      padding: 14px 26px;
      margin-right: 15px;

      svg {
        margin: 0 5px;
      }
    }
  }

  .categoryPopup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    z-index: 1100;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;

    .close {
      position: absolute;
      top: 6%;
      right: 5%;
      padding: 10px;
      background: $pro;
      border-radius: 20px;

      svg {
        width: 15px;
        height: 15px;

        path {
          stroke: $white;
        }
      }
    }

    .list {
      margin: 20px 0 50px;
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      overflow: scroll;

      &>div {
        display: flex;
        flex-direction: column;
        width: 320px;
        max-width: 100%;
        justify-content: flex-start;
        margin: 20px 40px;

        .title {
          text-align: center;
          margin-bottom: 15px;
        }
      }

      .line {
        display: flex;
        align-items: center;
        text-align: left;

        .icon {
          width: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
        }

        span {
          flex: 1;
          padding: 12px 0;
        }

        .checkbox {
          height: 20px;
          width: 20px;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: solid 2px;

          &.checked {
            background: currentColor;
          }

          svg {
            height: 80%;
            width: 80%;
          }
          path {
            fill: $white;
          }
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    justify-content: flex-start;
    margin: 0 20px;
  }
}

.pac-item:hover, .pac-item-selected {
  background: $gray300;
}
