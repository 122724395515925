.ArticlesCarousel {
  $mobileHorizontalPadding: 40px;
  $mobilePadding: 70px 0px;
  $desktopPadding: 90px 0 90px 50px;
  $mobileGap: 30px;
  $desktopGap: 20px;

  padding: $mobilePadding;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $mobileGap;
  border-bottom: 1px solid #21212B33;

  .ButtonV2--clear.ButtonV2--link {
    text-decoration: none;
  }

  .ArticlesCarouselHeader {
    width: 100%;
    padding: 0 $mobileHorizontalPadding;
  }

  .ArticlesCarousel__carousel {
    position: relative;
    width: 100%;

    .swiper,
    .swiper-wrapper {
      width: 100%;
      max-height: 500px;
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }

    .swiper-slide {
      height: auto;

      &.swiper-slide-active {
        .ArticleCard {
          .ArticleCard__image {
            scale: 1.1;
          }
        }
      }
    }

    .ArticlesCarousel__carousel__pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin-top: 30px;

      .ArticlesCarousel__carousel__pagination__button {
        width: 10px;
        height: 10px;
        scale: 0.8;
        background: $white;
        opacity: 0.4;
        border-radius: 100%;
        transition: all 0.2s ease-in-out;

        &.ArticlesCarousel__carousel__pagination__button--active {
          opacity: 1;
          scale: 1;
        }
      }
    }

    .ArticlesCarousel__carousel__navigationButton {
      display: none;
    }
  }

  @media screen and (min-width: 1024px) {
    padding: $desktopPadding;
    flex-direction: row;
    gap: $desktopGap;

    .ArticlesCarouselHeader {
      min-width: 433px;
      max-width: 433px;
      padding: 0 100px;
    }

    .ArticlesCarousel__carousel {
      display: inline-grid;

      .swiper,
      .swiper-wrapper {
        max-height: none;
        cursor: default;
        pointer-events: none;

        &:active {
          cursor: default;
        }
      }

      .swiper {
        padding: 0;
      }

      .ArticleCard {
        pointer-events: all;
      }

      .ArticlesCarousel__carousel__pagination {
        display: none;
      }

      .ArticlesCarousel__carousel__navigationButton {
        position: absolute;
        top: 50%;
        translate: 0 -50%;
        z-index: 1;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: $white;
        box-shadow: 0 2px 3px 0 #00000026;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          background: $gray100;
        }

        svg {
          width: 14px;

          path,
          line {
            stroke: $black;
          }
        }

        &.ArticlesCarousel__carousel__navigationPrev {
          left: 0;
          translate: -50% -50%;
        }

        &.ArticlesCarousel__carousel__navigationNext {
          right: 5%;
        }
      }
    }
  }
}
