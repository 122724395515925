.heroPerso {
  width: 100%;
  position: relative;
  height: 270px;
  margin-bottom: 70px;

  &.noContent {
    margin-bottom: 0;
  }

  &.withMeteo {
    height: 320px;
  }

  .background {
    position: absolute;
    object-position: center;
    object-fit: cover;
    height: 100%;
    width: 100%;
    filter: brightness(0.8);
  }

  .container {
    position: absolute;
    bottom: 50px;
    left: 20px;
    right: 20px;
    color: $white;
    h1 {
      font-size: 30px;
      line-height: 30px;
    }
    h2 {
      font-size: 16px;
      line-height: 25px;
    }
  }

  .content {
    display: flex;
    position: absolute;
    bottom: -70px;
    padding: 0 20px;
    width: 100%;
    overflow: auto;
    gap: 10px;

    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0;

      img {
        height: 85px;
        width: 85px;
        border: solid 3px $pro;
        border-radius: 50px;
        margin-bottom: 5px;
      }

      div {
        font-size: 12px;
        font-weight: bold;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .container {
      left: 40px;
      right: 40px;
    }
    .content {
      padding: 0 40px;
    }
  }

  @media screen and (min-width: 1024px) {
    height: 350px;
    margin-bottom: 120px;

    .container {
      bottom: 100px;

      h1 {
        font-size: 40px;
        line-height: 40px;
      }
    }

    &.withMeteo {
      height: 350px;

      .container {
        right: 340px;
      }

      .meteo {
        position: absolute;
        top: 0;
        right: -300px;
      }
    }

    .content {
      bottom: -120px;
      gap: 20px;

      button {
        img {
          height: 145px;
          width: 145px;
          border: solid 4px $pro;
          border-radius: 80px;
          margin-bottom: 10px;
        }

        div {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }
}
