.lightSearchBar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .inputBlock {
    position: relative;
    margin: 8px 15px;
    width: 400px;
    max-width: calc(100% - 104px);

    input {
      background: $white;
      padding: 12px 20px 12px 30px;
      border-radius: 20px;
      color: $black;
      width: 100%;
      border: solid 1px $black;
      height: 41px;
    }

    svg {
      position: absolute;
      left: 10px;
      top: 11px;
    }
  }

  .validate {
    height: 44px;
    width: 44px;
    padding: 0;
    margin-right: 30px;
  }

  .filters {
    display: flex;
    margin: 8px 0;

    .categoryButton, .mapButton {
      display: flex;
      align-items: center;
      padding: 14px 26px;
      margin-right: 15px;

      svg {
        margin: 0 5px;
      }
    }
  }

  .categoryPopup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    z-index: 1100;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;

    .close {
      position: absolute;
      top: 6%;
      right: 5%;
      padding: 10px;
      background: $pro;
      border-radius: 20px;

      svg {
        width: 15px;
        height: 15px;

        path {
          stroke: $white;
        }
      }
    }

    .list {
      margin: 20px 0 50px;
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      overflow: scroll;

      &>div {
        display: flex;
        flex-direction: column;
        width: 320px;
        max-width: 100%;
        justify-content: flex-start;
        margin: 20px 40px;

        .title {
          text-align: center;
          margin-bottom: 15px;
        }
      }

      .line {
        display: flex;
        align-items: center;
        text-align: left;

        .icon {
          width: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;

          svg {
            height: 60%;
            width: auto;
          }
        }

        span {
          flex: 1;
          padding: 12px 0;
        }

        .checkbox {
          height: 20px;
          width: 20px;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: solid 2px;

          &.checked {
            background: currentColor;
          }

          svg {
            height: 80%;
            width: 80%;
          }
          path {
            fill: $white;
          }
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    justify-content: flex-start;
    margin: 0 20px;
  }
}

.pac-item:hover, .pac-item-selected {
  background: $gray300;
}
