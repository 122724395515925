.ForgotPasswordPage {
  $headerHeight: 90px;

  margin-top: $headerHeight;
  padding: 50px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .Form {
    width: 100%;
  }

  p {
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
  }

  .ForgotPasswordPage__noAccount {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 20px;
  }

  @media screen and (min-width: 768px) {
    margin-top: 0;

    .Form {
      padding: 0 94px;

      .Form__fields,
      .Form__submitContainer {
        padding: 0 56px;
      }
    }

    p {
      font-size: 14px;
      line-height: 24px;
    }
  }
}