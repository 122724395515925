.Toggle {
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $black;
  border-radius: 100px;
  overflow: hidden;

  .toggle-inner {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: $black;
    border-radius: 100px;
    transition: all 0.3s ease-in-out;
  }

  .toggle-item {
    z-index: 1;
    border-radius: 100px;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background: none;
    color: $black;
    transition: all 0.3s ease-in-out;

    &.selected {
      color: $white;
    }

    svg {
      width: 24px;
      height: 24px;

      * {
        fill: currentColor;
      }
    }

    span {
      font-family: $mainFont;
      font-weight: 500;
      font-size: 12px;
    }
  }
}
