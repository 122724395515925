.FavoritesPage {
  $mobilePadding: 0;
  $desktopPadding: 10px 0 0 0;

  flex: 1;
  width: 100%;
  padding: $mobilePadding;

  .FavoritesPage__groups {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .NewFavoritesGroupButton {
    margin: 30px 0 0 0;
  }

  @media screen and (min-width: 768px) {
    padding: $desktopPadding;

    .NewFavoritesGroupButton {
      margin: 50px 0 0 0;

      .NewFavoritesGroupButton__button {
        margin: 0;
      }
    }
  }
}
