.ButtonV2 {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 3px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }

  &:focus,
  &:focus-visible {
    outline: 1px solid $black;
  }

  /* Button variants */
  &.ButtonV2--solid {
    background: $black;
    border: 1px solid $black;
    color: $white;

    &:focus,
    &:focus-visible {
      outline: thick double $black;
    }

    .ButtonV2__loader {
      svg {
        path {
          &:nth-child(2) {
            fill: $white;
          }
        }
      }
    }
  }

  &.ButtonV2--outline {
    background: $white;
    border: 1px solid #21212B33;
    color: $black;

    &:hover {
      opacity: 1;
      background: $gray100;
    }
  }

  &.ButtonV2--clear {
    padding: 0;
    background: transparent;
    border: 1px solid transparent;
    color: $black;

    &.ButtonV2--link {
      text-decoration: underline;
    }
  }

  /* Button alignments */
  &.ButtonV2--left {
    justify-content: flex-start;
  }

  &.ButtonV2--center {
    justify-content: center;
  }

  &.ButtonV2--right {
    justify-content: flex-end;
  }

  &.ButtonV2--filledIcon {
    svg {
      fill: currentColor;
    }
  }

  svg {
    overflow: visible;
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;

    &.withStroke {
      path {
        stroke: currentColor;
      }
    }

    &.envelopeV2,
    &.facebookV2,
    &.mapPin,
    &.instagramV2,
    &.youtubeV2 {
      path {
        fill: none;
      }

      rect,
      path {
        stroke: currentColor;
      }
    }

    &.facebookV2,
    &.whatsApp {
      path {
        &:nth-child(1) {
          fill: currentColor;
        }
      }
    }

    &.youtubeV2 {
      path {
        &:nth-child(2) {
          fill: currentColor;
        }
      }
    }

    path,
    ellipse,
    line {
      stroke: currentColor;
    }
  }

  .ButtonV2__text {
    font-family: $mainFont;
    font-size: 13px;
    line-height: 20px;
    font-weight: 700;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    word-break: break-word;
  }

  @media screen and (min-width: 768px) {
    padding: 11px 15px;

    .ButtonV2__text {
      font-size: 14px;
      line-height: 24px;
    }

    svg {
      min-width: 24px;
      min-height: 24px;
      width: 24px;
      height: 24px;
    }
  }
}