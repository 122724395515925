.ManagementStoreV2 {
  $gap: 20px;
  $headerGap: 10px;

  display: flex;
  flex-direction: column;
  gap: $gap;
  font-family: $mainFont;
  text-align: center;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $headerGap;

    &__label {
      font-size: 13px;
      line-height: 20px;
      font-weight: 500;
    }

    &__name {
      font-size: 14px;
      line-height: 22px;
      font-weight: 600;
      display: flex;
      align-items: center;
    }

    &__link {
      text-decoration: underline;
    }

    &:hover {
      svg {
        rotate: 0deg;
      }
    }

    svg {
      rotate: -45deg;
      margin-left: 6px;
      min-width: 13px;
      min-height: 13px;
      width: 13px;
      height: 13px;
      transition: all 0.2s ease;

      path,
      line {
        stroke: currentColor;
      }
    }
  }


  @media screen and (min-width: 768px) {
    text-align: left;

    &__header {
      align-items: flex-start;

      &__label {
        font-size: 14px;
        line-height: 24px;
      }

      &__name {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
