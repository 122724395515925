.editoPage {
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .hero {
    position: relative;
    height: 310px;
    width: 100%;
    display: flex;
    align-items: flex-end;

    .background {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      height: 100%;
      width: 100%;
      filter: brightness(0.75);
    }

    .shop_header_info {
      margin: 10px 5% 30px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-evenly;
      color: $white;
      width: 100%;
      z-index: 2;
    }

    .category {
      color: $white;
      font-family: $mainFont;
      font-size: 13px;
      font-weight: 500;
      padding: 5px 12px 5px;
      text-transform: uppercase;

      svg {
        height: 11px;

        path {
          stroke: transparent;
          fill: $white;
        }
      }
    }

    .name {
      color: $white;
      font-family: $mainFont;
      font-weight: bold;
      padding: 8px 0 3px;
      font-size: 29.4px;
      line-height: 30px;
      /* or 102% */
      letter-spacing: 0.7px;
    }

    .location {
      margin-top: 2px;
      svg {
        margin-right: 5px;
        width: 9px;
      }
    }

    .location, .category {
      path, circle {
        stroke: $white
      }
    }
  }

  .content {
    // TODO grid ou flex
    margin: 23px 5%;
  }

  .contact {
    margin-top: 20px;

    .title {
      font-size: 25px;
      font-weight: 700;
      margin: 10px 0 5px;
    }

    .contact-element {
      display: flex;
      align-items: center;
      justify-content: start;
      margin: 15px 0;
      font-weight: 300;
      line-height: 18px;

      svg {
        margin-right: 20px;

        * {
          stroke: $client;
        }
      }
    }

    .Button {
      margin-top: 5px;
    }
    .share {
      display: flex;
      margin: 10px 0;
      gap: 10px;
    }
  }

  .label {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px;
    border: solid 1px $client;
    margin: 40px 0 0;

    img {
      height: 30px;
      width: 30px;
      object-fit: contain;
    }

    .text {
      margin-left: 15px;
      font-size: 12px;
      font-weight: 700;
      color: $client;
      line-height: 20px;
      text-transform: uppercase;

      br {display: none;}
    }
  }

  .mapBlock {
    .title {
      font-size: 25px;
      font-weight: 700;
      margin: 30px 0 15px;
    }

    .map {
      width: 100%;
      height: 300px;
    }

    .address {
      font-size: 12px;
      margin: 10px 0 5px;
    }
  }

  @media screen and (min-width: 760px) {
    .hero {
      .name {
        max-width: 65%;
      }
    }
    .content {
      width: 55%;
      min-width: calc(85% - 350px);
    }
    .sideBlock {
      position: absolute;
      width: 30%;
      top: 310px;
      right: 5%;
      max-width: 350px;

      .advantages {
        margin: -125px 0 25px;
      }
    }

    .label {
      width: 305px;
      padding: 20px;

      img {
        height: 40px;
        width: 40px;
      }

      .text {
        margin-left: 20px;
        br {display: initial;}
      }
    }
  }

  /*import from detour en france*/
  .editoContent {
    color: #666;
    .node .field {
      margin-bottom: 0.8em;
    }
    span.file-image, span.file-image .content {
      display: block;
    }
    span.file-image .content img {
      margin-bottom: 1em;
      display: block;
    }
    img {
      max-width: 100%;
      height: auto;
      -webkit-transition: border-color 0.2s;
      -moz-transition: border-color 0.2s;
      -o-transition: border-color 0.2s;
      transition: border-color 0.2s;
    }
    p {
      font-size: 1.067em;
      line-height: 1.4em;
      text-align: justify;
    }
    h2 {
      font-size: 1.7em;
    }
    h1, h2, h3, h4, h5, h6 {
      margin-top: 1em;
      line-height: 1.1em;
      font-weight: normal;
      color: #1F1F1F;
    }
    h1, h2, h3, h4, h5, h6, p, img, ul, ol, video, audio, table, blockquote, q, .jwplayer-video, .jwplayer-audio, .jp-video, .jp-audio {
      margin-bottom: 0.8em;
    }
    ul, ol {
      margin-left: 2em;
    }
    ul li, ul.menu li {
      list-style-type: disc;
    }
    a:link, a:active, a:visited {
      font-weight: bold;
      color: $links;
      -webkit-transition: color 0.2s,background-color 0.2s;
      -moz-transition: color 0.2s,background-color 0.2s;
      -o-transition: color 0.2s,background-color 0.2s;
      transition: color 0.2s,background-color 0.2s;
    }
    a {
      text-decoration: none;
    }
    h1, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
      margin-top: 0;
    }
    h3 {
      font-size: 1.3em;
      margin-top: 30px;
    }
    .media-wysiwyg-align-right, .media-float-right {
      float: right;
      margin-left: 20px;
    }
    .element-invisible {
      width: 1px;
      position: absolute !important;
      clip: rect(1px 1px 1px 1px);
      clip: rect(1px,1px,1px,1px);
      overflow: hidden;
      height: 1px;
    }
    .content {
      width: initial;
    }

    .field-name-body {
      img {
        height: auto !important;
      }
      .media-element-container img, .media-element-container span.file-image .content img {
        margin-bottom: 1em;
      }
      .media-element-container+p {
        margin-top: 1em;
      }
      p {
        margin-bottom: 1.25em;
        margin-top: 1.25em;
      }
      h2 {
        color: #585858;
        font-size: 1.5em;
        margin-bottom: 0.9em;
        margin-top: 1.33em;
      }
      h3 {
        color: #585858;
        font-size: 1.3em;
        margin: 20px 0;
      }
    }
    .textbox {
      border: 1px solid #D3E2E6;
      clear: both;
      display: block;
      margin-bottom: 20px;
      margin-top: 20px;
      padding: 20px;

      h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
        margin-top: 0;
      }

      h2, h3, h4, h5, h6 {
        color: rgb(117, 194, 208);
        color: rgba(117, 194, 208, .8);
      }

      h2, h3, h4, h5, h6 {
        color: #5c9795;
        color: rgba(92, 151, 149, 0.8);
        font-size: 1.33em;
        margin-bottom: 0.35em;
      }
      p:last-child {
        margin-bottom: 0;
      }
      p, p:first-child {
        margin-top: 0;
        padding-left: 15px;
      }
      &:after {
        clear: both;
        content: '.';
        display: block;
        height: 0;
        visibility: hidden;
      }
    }
  }
}