/* COLORS */
$white: var(--white, #FFFFFF);
$black: var(--black, #000000);
$black60: var(--black60, #00000099);
$black25: var(--black25, #00000040);
$green: var(--green, #000);
$green-light: var(--green-light, #fff);
$links: var(--links, #0A94A8);
$yellow: var(--yellow, #fce84e);
$orange: var(--orange, #F7AC6F);
$blue: var(--blue, #96C0E8);

/* Categories */
$client: var(--client, #009989);
$pro: var(--pro, #3B56CA);

$ou-sejourner: var(--ou-sejourner, #09507C);
$monuments-et-musees: var(--monuments-et-musees, #E79EC2);
$a-table: var(--a-table, #DA3A5C);
$producteurs-et-artisans: var(--producteurs-et-artisans, #67CEBF);
$voyages: var(--voyages, #308276);
$loisirs: var(--loisirs, #DFCC3F);
$commerce: var(--commerce, #38ec91);
$maison-jardin: var(--maison-jardin, #e44747);
$alimentaire: var(--alimentaire, #1a5dc1);
$mode: var(--mode, #c11a84);
$beaute-sante: var(--beaute-sante, #f6aada);
$evenementiel: var(--evenementiel, #3b56dc);

$detours-en-france: var(--detours-en-france, #44A1D0);

/* Gray */
$gray900: var(--gray900, #071621);
$gray700: var(--gray700, #666E87);
$gray500: var(--gray500, #8A90A7);
$gray300: var(--gray300, #BEC6D3);
$gray100: var(--gray100, #E9EAED);
$gray50: var(--gray50, #FAFAFA);

/* Search colors */
$searchColors: (
        country: (background: var(--bgcountry, #d7f4df), color: var(--colorcountry, #276338)),
        region: (background: var(--bgregion, #d7f4f1), color: var(--colorregion, #27635d)),
        city: (background: var(--bgcity, #FBF6D0), color: var(--colorcity, #544E21)),
        address: (background: var(--bgaddress, #D0DCFB), color: var(--coloraddress, #212F54)),
        other: (background: var(--bgother, #E6E6E6), color: var(--colorother, #404040)),
);

/* Travel Planer colors */
$travelPlanerPrimaryColor: var(--travelPlanerPrimaryColor, #000);
$travelPlanerSecondaryColor: var(--travelPlanerSecondaryColor, #000);
$travelPlanerSearchMapBackground: var(--travelPlanerSearchMapBackground);
$travelPlanerSearchMapActiveBackground: var(--travelPlanerSearchMapActiveBackground);
$travelPlanerSearchMapInactiveBackground: var(--travelPlanerSearchMapInactiveBackground);
$travelPlanerPartnerPrimary: var(--travelPlanerPartnerPrimary);
$travelPlanerButtonBackground: var(--travelPlanerButtonBackground);
$travelPlanerButtonColor: var(--travelPlanerButtonColor);

/*Categories classes*/
.ou-sejourner {color: $ou-sejourner; &.catBackground, .catBackground {background: $ou-sejourner}; & .icon svg path, &.icon svg path {fill: $ou-sejourner;}  }
.monuments-et-musees {color: $monuments-et-musees; &.catBackground, .catBackground {background: $monuments-et-musees }; & .icon svg path, &.icon svg path {fill: $monuments-et-musees;}  }
.a-table {color: $a-table; &.catBackground, .catBackground {background: $a-table}; & .icon svg path, &.icon svg path {fill: $a-table;}  }
.producteurs-et-artisans {color: $producteurs-et-artisans; &.catBackground, .catBackground {background: $producteurs-et-artisans}; & .icon svg path, &.icon svg path {fill: $producteurs-et-artisans;}  }
.voyages {color: $voyages; &.catBackground, .catBackground {background: $voyages}; & .icon svg path, &.icon svg path {fill: $voyages;}  }
.loisirs {color: $loisirs; &.catBackground, .catBackground {background: $loisirs}; & .icon svg path, &.icon svg path {fill: $loisirs;}  }
.commerce {color: $commerce; &.catBackground, .catBackground {background: $commerce}; & .icon svg path, &.icon svg path {fill: $commerce;} }
.detours-en-france {color: $detours-en-france; &.catBackground, .catBackground {background: $detours-en-france}; & .icon svg path, &.icon svg path {fill: $detours-en-france;}  }
.maison-jardin {color: $maison-jardin; &.catBackground, .catBackground {background: $maison-jardin}; & .icon svg path, &.icon svg path {fill: $maison-jardin;}  }
.alimentaire {color: $alimentaire; &.catBackground, .catBackground {background: $alimentaire}; & .icon svg path, &.icon svg path {fill: $alimentaire;}  }
.mode {color: $mode; &.catBackground, .catBackground {background: $mode}; & .icon svg path, &.icon svg path {fill: $mode;}  }
.beaute-sante {color: $beaute-sante; &.catBackground, .catBackground {background: $beaute-sante}; & .icon svg path, &.icon svg path {fill: $beaute-sante;}  }
.evenementiel {color: $evenementiel; &.catBackground, .catBackground {background: $evenementiel}; & .icon svg path, &.icon svg path {fill: $evenementiel;}  }