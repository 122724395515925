.ImagesCarousel {

  .ImagesCarousel__carousel {
    position: relative;

    .ImagesCarousel__carousel__navigationButton {
      position: absolute;
      top: 50%;
      translate: 0 -50%;
      z-index: 1;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: #21212B66;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        background: #21212B;
      }

      svg {
        path {
          stroke: $white;
        }
      }

      &.ImagesCarousel__carousel__navigationPrev {
        left: 10px;

        svg {
          margin-left: -2px;
        }
      }

      &.ImagesCarousel__carousel__navigationNext {
        right: 10px;

        svg {
          margin-right: -2px;
        }
      }
    }
  }
}