.starNote {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 700;

  .starList {
    display: flex;
    margin: 0 5px 0 -1px;
  }

  .star {
    height: 9px;
    width: 10px;
    margin: 0 1px;

    svg {
      height: 100%;
      width: 100%;
    }

    path {
      stroke: $yellow;
    }

    &.full {
      svg {fill: $yellow}
    }
  }
}