.HeroSearch {
  width: 100%;
  position: relative;
  padding-top: 1px;
  margin-top: -1px;

  .background {
    position: absolute;
    object-position: center;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .container {
    z-index: 2;
    margin: 160px auto 0 auto;
    max-width: 800px;
    position: relative;
    padding: 0 25px;

    .title {
      color: $white;
      font-family: $titleFont;
      font-size: 85px;
      font-weight: 400;
      line-height: 42px;
      text-align: center;
      text-transform: none;
      letter-spacing: -0.27px;
    }

    .subtitle {
      font-family: $mainFont;
      font-weight: bold;
      color: $yellow;
      font-size: 32px;
      line-height: 33px;
      text-align: center;
      letter-spacing: 0.8px;
    }

    .search {
      margin: 12px auto 0 auto;
      text-align: center;
      color: $white;

      .searchBar {
        justify-content: center;
        margin: 0;
      }
    }

    .content {
      max-width: 480px;
      margin: 12px auto 0 auto;
      color: $white;
      font-family: $mainFont;
      font-weight: bold;
      font-size: 13px;
      line-height: 1.5;
      text-align: center;
    }
  }

  .bottom-links {
    z-index: 1;
    padding: 25px;
    position: relative;
    color: $white;
    font-size: 12px;
    line-height: 11px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .link {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;

      &:hover {
        text-decoration: underline;
      }

      svg {
        path {
          stroke: $white;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {

  }

  @media screen and (min-width: 1024px) {
    .container {
      margin-top: 280px;

      .title {
        font-size: 110px;
      }

      .subtitle {
        font-size: 42px;
        line-height: 54px;
        letter-spacing: 1px;
      }

      .content {
        font-size: 18px;
        line-height: 28px;
      }
    }

    .bottom-links {
      justify-content: flex-end;
      margin: 0 0 0 auto;
      padding: 32px 75px;
      gap: 25%;

      .link {
        justify-content: flex-end;

        img {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
}
