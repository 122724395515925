.PictoHoverWithName {
  position: relative;
  display: inline-block;
  overflow: visible;
  width: fit-content;

  &::after {
    content: attr(title);
    display: none;
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px;
    white-space: nowrap;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    translate: -50%;
  }

  &:hover::after {
    display: block;
  }
}
