.lightSearchGrid {
  padding: 10px 5px;

  .grid {
    &>.title {
      font-size: 25px;
      line-height: 30px;
      margin: 20px 15px 0;
      font-weight: bold;
    }

    .results {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px 10px;
      margin-top: 8px;
    }
  }

  .noResult {
    font-size: 25px;
    line-height: 31px;
    margin: 20px 15px 0;

    .loader {
      display: flex;
      justify-content: center;

      svg circle {
        fill: $client
      }
    }
  }

  .filters {
    margin: 10px 10px 0;

    .filter {
      cursor: pointer;
      padding: 6px 14px;
      border-radius: 2em;
      border: 1px solid;
      font-size: 12px;
      font-family: $mainFont;
      display: inline-flex;
      align-items: center;
      margin: 4px;

      svg {
        height: 10px;
        width: 10px;
        margin-left: 10px;
      }
    }
  }

  .map {
    height: 400px;
    width: calc(100% + 10px);
    margin: 20px -5px;

    .gm-style-iw.gm-style-iw-c {
      padding: 0;
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      background: $client;
      color: $white;
      padding: 8px 12px;
      margin: 3px;
      border-radius: 20px;
    }

    span {
      margin: 3px;
    }
  }

  .carousel {
    margin: 10px 0;

    .header {
      font-size: 25px;
      margin: 0 10px 10px;
      line-height: 32px;
      text-align: left;
      font-family: $mainFont;
      font-weight: bold;

      span {
        font-size: inherit;
        font-weight: inherit;
        text-transform: inherit;
        color: inherit;
      }
    }

    @media screen and (min-width: 768px) {
      padding: 24px 25px;

      .header {
        font-size: 30px;
        margin: 0 10px 20px;
      }
    }
  }

  @media screen and (min-width: 750px) {
    .noResult {
      font-size: 30px;
      line-height: 35px;
      margin: 20px auto 0 35px;
    }
    .filters {
      margin: 10px 35px 0;
    }

    .map {
      height: 675px;
      width: 90%;
      margin: 20px auto;
    }

    .grid {
      &>.title {
        margin-left: 35px;
        line-height: 35px;
        font-size: 30px;
      }
      .results {
        margin-top: 20px;
      }
    }
  }

  @import './SearchBar';
}
