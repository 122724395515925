.ImagesGalleryCarousel {
  .ImagesGalleryCarousel__carousel {
    position: relative;

    .swiper,
    .swiper-wrapper {
      cursor: grab;
      max-height: 360px;

      &:active {
        cursor: grabbing;
      }
    }

    .swiper-slide {
      height: auto;
    }

    .ImagesGalleryCarousel__carousel__slide {
      display: grid;
      grid-column-gap: 20px;
      grid-row-gap: 20px;

      &.ImagesGalleryCarousel__carousel__slide--1 {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;

        img {
          &:nth-child(1) {
            grid-area: 1 / 1 / 2 / 2;
          }
        }
      }

      &.ImagesGalleryCarousel__carousel__slide--2 {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);

        img {
          &:nth-child(1) {
            grid-area: 1 / 1 / 2 / 2;
          }

          &:nth-child(2) {
            grid-area: 2 / 1 / 3 / 2;
          }
        }
      }

      &.ImagesGalleryCarousel__carousel__slide--3 {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(9, 1fr);

        img {
          &:nth-child(1) {
            grid-area: 1 / 1 / 7 / 3;
          }

          &:nth-child(2) {
            grid-area: 7 / 1 / 10 / 2;
          }

          &:nth-child(3) {
            grid-area: 7 / 2 / 10 / 3;
          }
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px;
        overflow: hidden;
      }
    }

    .ImagesGalleryCarousel__carousel__pagination {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 30px;

      .ImagesGalleryCarousel__carousel__pagination__button {
        width: 10px;
        height: 10px;
        scale: 0.8;
        background: $black;
        opacity: 0.4;
        border-radius: 100%;
        transition: all 0.2s ease-in-out;

        &.ImagesGalleryCarousel__carousel__pagination__button--active {
          opacity: 1;
          scale: 1;
        }
      }
    }

    .ImagesGalleryCarousel__carousel__navigationButton {
      display: none;
    }
  }

  @media screen and (min-width: 768px) {
    .ImagesGalleryCarousel__carousel {

      .ImagesGalleryCarousel__carousel__slide {
        &.ImagesGalleryCarousel__carousel__slide--2 {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: 1fr;

          img {
            &:nth-child(1) {
              grid-area: 1 / 1 / 2 / 2;
            }

            &:nth-child(2) {
              grid-area: 1 / 2 / 2 / 3;
            }
          }
        }

        &.ImagesGalleryCarousel__carousel__slide--3 {
          grid-template-columns: repeat(10, 1fr);
          grid-template-rows: repeat(2, 1fr);

          img {
            &:nth-child(1) {
              grid-area: 1 / 1 / 3 / 7;
            }

            &:nth-child(2) {
              grid-area: 1 / 7 / 2 / 11;
            }

            &:nth-child(3) {
              grid-area: 2 / 7 / 3 / 11;
            }
          }
        }
      }

      .ImagesGalleryCarousel__carousel__navigationButton {
        position: absolute;
        top: 50%;
        translate: 0 -50%;
        z-index: 1;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: $white;
        box-shadow: 0 2px 3px 0 #00000026;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          background: $gray100;
        }

        svg {
          width: 14px;

          path,
          line {
            stroke: $black;
          }
        }

        &.ImagesGalleryCarousel__carousel__navigationPrev {
          left: -5px;
          translate: -100% -50%;
        }

        &.ImagesGalleryCarousel__carousel__navigationNext {
          right: -5px;
          translate: 100% -50%;
        }
      }
    }
  }
}
