.Radio {
  $mobileGap: 5px;
  $desktopGap: 0;

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $mobileGap;
  font-family: $mainFont;

  .Radio__label {
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    color: #21212B99;
  }

  .Radio__options {
    display: flex;
    flex-direction: column;

    .Radio__options__option {
      display: flex;
      align-items: center;
      cursor: pointer;

      &.selected {
        .Radio__options__circle__inner {
          opacity: 1;
        }
      }

      input {
        position: relative;
        width: 22px;
        height: 22px;
        min-width: 22px;
        min-height: 22px;
        cursor: pointer;
        accent-color: #21212B33;

        &:before {
          width: 22px;
          height: 22px;
          background: $gray100;
          border-radius: 50%;
          position: relative;
          content: '';
          display: inline-block;
          visibility: visible;
          border: 1.22px solid #21212B33;
        }

        &:checked {
          &:after {
            content: '';
            width: 12px;
            height: 12px;
            background: $black;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      label {
        padding: 11px 0 11px 10px;
        font-size: 13px;
        line-height: 20px;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }

  @media screen and (min-width: 768px) {
    gap: $desktopGap;

    .Radio__label {
      font-size: 14px;
      line-height: 24px;
    }

    .Radio__options {
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 40px;
      row-gap: 0;

      .Radio__options__option {
        label {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
}
