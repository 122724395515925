.Input {
  $mobileGap: 10px;
  $desktopGap: 6px;
  $errorColor: #FF212B;

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $mobileGap;
  font-family: $mainFont;

  &.Input--error {
    .Input__container {
      .Input__container__input {
        border-color: $errorColor;
      }
    }
  }

  .Input__header {
    display: flex;
    align-items: center;
    gap: 10px;

    .Input__label {
      font-size: 13px;
      line-height: 20px;
      font-weight: 500;
      color: #21212B99;
    }

    .Input__forgot {
      margin-left: auto;

      .ButtonV2__text {
        font-weight: 500;
        white-space: nowrap;
      }
    }
  }

  .Input__container {
    width: 100%;
    position: relative;

    .Input__container__input {
      width: 100%;
      padding: 11px 15px;
      border: 1px solid #21212B33;
      border-radius: 3px;
      font-size: 13px;
      line-height: 20px;
      font-weight: 500;

      &:focus {
        border-color: $black;
      }
    }

    .Input__container__toggle-password {
      position: absolute;
      top: 50%;
      right: 15px;
      translate: 0 -50%;
      text-decoration: underline;
    }
  }

  .Input__error {
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    color: $errorColor;
  }

  @media screen and (min-width: 768px) {
    gap: $desktopGap;

    .Input__header {
      .Input__label {
        font-size: 14px;
        line-height: 24px;
      }
    }

    .Input__container {
      width: 100%;
      position: relative;

      .Input__container__input {
        font-size: 14px;
        line-height: 24px;
      }
    }

    .Input__error {
      font-size: 14px;
      line-height: 24px;
    }
  }
}