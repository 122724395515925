.Engine {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: left;
  min-height: 500px;

  #response-engine-root {
    position: relative;
    width: 100%;
    height: 100%;
    flex: 1;
    text-align: left;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    
    > * {
      width: 100%;
      height: auto;
      text-align: left;
      min-height: min-content;
    }

    h1, h2, h3, h4, h5, h6, p, span, div {
      text-align: left;
    }

    .StickyItem {
      width: fit-content;

      @media screen and (max-width: 767px) {
        .FavoriteButton {
          padding: 10px;
          border-radius: 1000px;
          border: none;
          background: none;

          .ButtonV2__text {
            display: none;
          }
        }
      }
    }

    h1 {
      text-transform: none;
    }

    hr {
      height: 1px;
      background: rgba(156, 163, 175, 0.5);
      border: none;
      margin: 1rem auto;
      width: 100%;
      opacity: 0.8;
    }

    p {
      font-size: 1rem;
    }
    .chat-input {
      position: relative;
      border-radius: 35px;
      background-color: white;
      border: 5px solid #E8E8E8;
      padding: 2px;
      
      .textarea-inner {
        border: 2px solid #666666;
        border-radius: 30px;
      }
      
      &:focus-within {
        outline: none;
      }
    }
  }
}
