body {
  position: relative;
  height: 100%;
  width: 100%;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    border-radius: 0;
  }
}

/* Scrollbar */
*::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: #fafafa;
  border-radius: 5px;

  &:hover {
    background: #f0f0f0;
  }
}

*::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 5px;

  &:hover {
    background: #b0b0b0;
  }
}

.page {
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
}