.ArticlesCarouselHeader {
  $mobileGap: 5px;
  $desktopGap: 10px;
  $mobilePadding: 0 20px;
  $desktopPadding: 0 80px;

  padding: $mobilePadding;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $mobileGap;

  .ArticlesCarouselHeader__title {
    margin-bottom: 10px;
    font-family: $mainFont;
    font-size: 28px;
    line-height: 38px;
    font-weight: 700;
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    padding: $desktopPadding;
    gap: $desktopGap;

    .ArticlesCarouselHeader__title {
      margin-bottom: 20px;
      font-size: 42px;
      line-height: 54px;
    }
  }
}
