.PageHeader {
  $gap: 50px;

  display: none;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: $gap;

    .PageHeader__header {
      display: flex;
      align-items: center;
      gap: 20px;

      svg {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;

        &.withStroke {
          path {
            stroke: currentColor;
          }
        }

        &.envelopeV2,
        &.facebookV2,
        &.instagramV2,
        &.youtubeV2 {
          path {
            fill: none;
          }

          rect,
          path {
            stroke: currentColor;
          }
        }

        &.facebookV2 {
          path {
            &:nth-child(1) {
              fill: currentColor;
            }
          }
        }

        &.youtubeV2 {
          path {
            &:nth-child(2) {
              fill: currentColor;
            }
          }
        }

        path,
        ellipse,
        line {
          stroke: currentColor;
        }
      }

      h1 {
        font-family: $mainFont;
        font-size: 32px;
        line-height: 40px;
        font-weight: 700;
        text-transform: none;
      }
    }
  }
}