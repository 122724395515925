.newsletter {
  margin: 30px 20px 20px;
  display: flex;
  background: $yellow;
  padding: 50px 30px 30px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .label {
    max-width: 310px;

    .title {
      font-size: 25px;
      line-height: 25px;
      font-weight: 700;
      position: relative;

      span {
        font-family: $titleFont;
        color: $pro;
        font-size: 52px;
        margin-left: 8px;
        font-weight: 400;
      }

      svg {
        position: absolute;
        top: -75px;
        right: -40px;
      }
    }
    .description {
      font-size: 12px;
      line-height: 18px;
      margin-top: 10px;
    }
  }
  .form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex: 1;
    max-width: 460px;
    margin: 0 10px;

    input {
      background: $white;
      display: block;
      min-width: 200px;
      font-size: 14px;
      padding: 15px;
      border-radius: 30px;
      margin: 20px;
      flex: 1;
    }

    div {
      color: $pro;
      font-size: 19px;
    }
  }

  @media screen and (min-width: 700px) {
    margin: 40px 100px 20px;
    padding: 30px 130px;

    .label {
      .title {
        font-size: 35px;
        line-height: 29px;

        span {
          font-size: 66px;
        }

        svg {
          top: -65px;
          right: -80px;
        }
      }
    }
  }
}