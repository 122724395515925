.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  padding: 20px 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 95;

  .header-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;

    .header-logo {
      width: 100%;
      max-width: 180px;
      object-fit: contain;

      svg {
        width: 100%;

        .col0,
        .col1,
        .col2 {
          fill: $white;
        }
      }
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 20px 0;
      gap: 16px;

      .navButton.hasPicto {
        padding: 10px 35px;
        svg {
          max-height: 20px;
          max-width: 20px;

          path {
            fill: currentColor;
          }
          circle {
            stroke: currentColor;
          }
        }
      }

      @media screen and (max-width: 1024px) {
        .navButton {
          display: none;
        }
        .navButton.hasPicto {
          display: flex;
          padding: 12px;

          span {
            display: none;
          }
        }
      }

      &.desktop-childs,
      &.mobile-childs {
        padding: 0;
      }

      &.desktop-childs {
        display: none;
      }

      .user-icon {
        padding: 12px 13px;

        img {
          min-width: 10px;
        }
      }

      .burger-menu {
        padding: 14px 12px;

        img {
          min-width: 10px;
        }
      }
    }
  }

  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;

    .close {
      position: absolute;
      top: 6%;
      right: 5%;
      padding: 10px;
      background: $pro;
      border-radius: 20px;

      svg {
        width: 15px;
        height: 15px;

        path {
          stroke: $white;
        }
      }
    }

    .list {
      margin: 20px 0 50px;
      width: 400px;
      max-width: calc(100% - 60px);
      display: flex;
      flex-direction: column;
      align-items: center;

      .line {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        color: $pro;
        font-size: 25px;
        font-weight: 700;
        line-height: 50px;

        &.current {
          color: $client;
        }

        &:hover {
          color: $client;
        }

        .separator {
          height: 2px;
          width: 50px;
          background: $client;
          margin-top: -10px;
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .header-content {
      .container {
        &.desktop-childs {
          display: flex;
        }

        &.mobile-childs {
          display: none;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    padding: 20px 40px;

    .header-content {
      .container {
        gap: 30px;
      }
    }
  }
}

/***************** HEADER WITH TAGLINE *****************/

.Header {
  .header-content {
    // .header-logo {
    //   max-width: 50%!important;
    // }

    .with-tagline {
      display: flex;
      img {
        max-width: 150px;
      }
      span {
        width: 25%;
        margin-left: 15px;
        font-size: 0.8rem;
        position: relative;
        &::before {
          content: '';
          display: block;
          height: 100%;
          width: 1px;
          background: lightgrey;
          position: absolute;
          bottom: 0;
          left: -0.5rem;
        }
      }
      @media screen and (max-width: 1024px) {
        span {
          width: 35%;
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .Header {
    .header-content {
      .with-tagline {
        span {
          display: none;
        }
      }
    }
    .LanguageSelector {
      font-size: 0.9rem;

      .select__control {
        background-color: #f4f1e8;
      }
    }
  }
}

// .Header {
//   .LanguageSelector {
//     width: 180px;
//   }
// }

/*** MODERN HEADER ***/

.c-Header.is-fixed {
  position: fixed;
  width: 100%;
  z-index: 1000;
  background: transparent;
}

.header-logo.is-small {
  transform: scale(0.8);
}

.header-logo {
  transition: transform 0.4s ease;
}

.c-Header.is-hidden {
  transform: translateY(-100%);
}

.c-Header:not(.is-fixed, .is-scrolled) {
  .c-Header-item {
    svg path,
    ellipse {
      stroke: black;
    }
  }

  .header-logo svg path:not(:first-child) {
    fill: black;
  }

  .c-Header-mobile-menu .c-Header-mobile-menu-line {
    background: black;
  }
}

.c-Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
  padding: 13px 15px;

  @media all and (min-width: 768px) {
    padding: 20px 30px;
  }

  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.2s ease-in-out;
  z-index: 9999;

  &.is-scrolled {
    background-color: white;
    box-shadow: 0 2px 3px 0 #00000026;

    .c-Header-mobile-menu .c-Header-mobile-menu-line {
      background: black;
    }

    @media all and (max-width: 768px) {
      .account {
        svg {
          fill: $black;
        }
      }
    }

    .header-logo {
      svg {
        height: 35px;

        path:not(:first-child) {
          fill: $black;
        }
      }
    }
  }

  .header-logo {
    svg {
      height: 40px;
      transition: all 0.2s ease-in-out;
    }
  }

  .header-logo {
    max-width: 150px;
    z-index: 1001;
  }

  #c-Header-insert {
    flex: 1;
    z-index: 1000;
    height: fit-content;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 6px;
    overflow: hidden;

    #c-Header-insert-1,
    #c-Header-insert-2 {
      overflow: hidden;

      & > div {
        overflow: hidden;
      }
    }

    #c-Header-insert-1 {
      display: flex;
      align-items: center;
    }

    #c-Header-insert-2-container {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 10px;

      #c-Header-insert-2 {
        display: flex;
        align-items: center;
        gap: 6px;

        & > div {
          display: flex;
          align-items: center;
        }

        .ButtonV2 {
          border: none;

          &.FavoriteButton,
          &.ShareButton,
          &.ReportAnomalyButton {
            padding: 8px;
            border-radius: 1000px;

            .ButtonV2__text {
              display: none;
            }
          }
        }
      }

      .separator-line {
        width: 1px;
        height: 24px;
        background: #21212b33;
      }
    }

    h1 {
      font-size: 14px;
      line-height: 22px;
      font-weight: 600;
      text-transform: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $black;
    }

    @media screen and (min-width: 768px) {
      margin-left: 10px;

      #c-Header-insert-2-container {
        gap: 20px;

        #c-Header-insert-2 {
          .ButtonV2 {
            &.FavoriteButton,
            &.ShareButton,
            &.ReportAnomalyButton {
              padding: 10px 20px;
              border-radius: 3px;

              &:focus,
              &:focus-visible {
                z-index: 1;
              }

              .ButtonV2__text {
                display: block;
                word-break: normal;
              }
            }
          }
        }
      }

      h1 {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
      }
    }
  }

  .header-logo.is-open {
    max-width: 150px;
    z-index: 1001;

    svg path:not(:first-child) {
      fill: black;
    }
  }

  @media all and (min-width: 768px) {
    &.is-scrolled {
      padding: 15px 20px;

      .header-logo {
        svg {
          height: 40px;
        }
      }

      .c-Header-item {
        svg {
          width: 40px;
        }
      }
    }

    .header-logo {
      max-width: 170px;

      svg {
        height: 60px;
      }
    }

    // .header-logo.is-open {
    //   max-width: 150px;
    //   z-index: 1001;

    //   svg path {
    //     fill: black;
    //   }
    // }

    .c-Header-items {
      display: flex;
      gap: 0;
      align-items: center;
      z-index: 1;

      &.is-scrolled {
        .c-Header-item {
          svg path,
          ellipse {
            stroke: black;
          }
        }
      }
    }

    .c-Header-item {
      transition: transform 0.4s ease;

      svg {
        width: 46px;
        height: auto;
      }
    }
  }

  .c-Header-mobile-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 6px;
    z-index: 1001;
    cursor: pointer;

    &:hover {
      .c-Header-mobile-menu-line.middle {
        width: 25px;
      }
    }

    .c-Header-mobile-menu-line {
      width: 25px;
      height: 2px;
      background: white;
      border-radius: 1px;
      transition: all 0.3s ease;

      &.top {
        transform: translateY(0);
      }

      &.middle {
        transform: scaleX(1);
        width: 20px;
        transition: all 0.3s;
      }

      &.bottom {
        transform: translateY(0);
        stroke-dasharray: 100%;
        stroke-dashoffset: 9px;
      }
    }

    &:hover {
      .c-Header-mobile-menu-line.bottom {
        stroke-dashoffset: 0;
      }
    }

    &.is-open {
      overflow: hidden;
      .c-Header-mobile-menu-line {
        background: black;

        &.top {
          transform: translateY(8px) rotate(45deg);
          stroke-linecap: round;
        }

        &.middle {
          transform: scaleX(0.1);
          opacity: 0;
        }

        &.bottom {
          transform: translateY(-8px) rotate(-45deg);
          stroke-linecap: round;
          stroke-dashoffset: 0;
        }
      }
    }
  }
}

/*** MODERN HEADER RESPONSIVE  ***/

@media all and (min-width: 768px) {
  .c-Header {
    .c-Header-mobile-menu {
      display: none;
    }
  }
}

/*** FULL SCREEN MENU ***/

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}

.menu-overlay.open {
  transform: translateX(0);
}

.menu-button {
  font-size: 24px;
  color: white;
  background: none;
  border: none;
  padding: 20px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  overflow: hidden; /* Masque la scrollbar */
  overflow-y: scroll; /* Permet le défilement vertical */
}

// body::-webkit-scrollbar {
//   display: none; /* Pour Chrome, Safari et Opera */
// }
