.Block {
  font-family: $mainFont;
  text-align: initial;

  h1 {
    font-size: 28px;
    line-height: 38px;
    font-weight: 700;
  }

  h2 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  }

  h3 {
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
  }

  h4 {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
  }

  h5 {
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
  }

  h6 {
    font-size: 10px;
    line-height: 18px;
    font-weight: 600;
  }

  a,
  p,
  span,
  li {
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
  }

  a {
    cursor: pointer;
    text-decoration: underline;
    position: relative;

    &:focus,
    &:focus-visible {
      outline: none;
    }
  }

  ul {
    list-style: circle;
    padding-left: 20px;

    li {
      list-style: disc;
    }
  }

  strong {
    font-weight: 700;

    * {
      font-weight: 700;
    }
  }

  @media screen and (min-width: 768px) {
    h1 {
      font-size: 42px;
      line-height: 54px;
    }

    h2 {
      font-size: 32px;
      line-height: 40px;
    }

    h3 {
      font-size: 22px;
      line-height: 32px;
    }

    h4 {
      font-size: 16px;
      line-height: 24px;
    }

    h5 {
      font-size: 14px;
      line-height: 22px;
    }

    h6 {
      font-size: 12px;
      line-height: 20px;
    }

    a,
    p,
    span,
    li {
      font-size: 14px;
      line-height: 24px;
    }
  }
}
