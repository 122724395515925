.listCategory {
  position: relative;

  .popup {    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 500px;
    max-width: 100%;
    margin: auto;
    background: $client;
    padding: 30px;
    color: $white;

    span {
      margin: 0 0 5px;
      display: inline-block;
    }

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 10px;
      background: $white;
      border-radius: 20px;

      svg {
        width: 15px;
        height: 15px;

        path {
          stroke: $client;
        }
      }
    }
  }
}