.copyright-container {
    position: absolute;
    // bottom: 120px;
    left: 20px;
    z-index: 10;
    
    .copyright-circle {
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      border-radius: 15px;
      display: flex;
      align-items: center;
      height: 30px;
      cursor: pointer;
      transition: width 0.5s cubic-bezier(0.4, 0, 0.2, 1);      
      width: 30px;
      overflow: hidden;
      white-space: nowrap;
  
      .copyright-symbol {
        font-size: 14px;

        // @media (min-width: 1024px) {
        //   width: 30px;
        // }
        text-align: center;
        flex-shrink: 0;
      }
  
      .copyright-text {
        font-size: 12px;
        opacity: 0;
        transform: translateX(-10px);
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
      }
      
      &:hover {
        width: var(--expanded-width, 200px);
        
        .copyright-text {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }