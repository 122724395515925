.accessibility-icons {
    display: inline-flex;
    vertical-align: middle;

    .accessibility-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px;
        background-color: #FFD930;
        
        svg {
            // background-color: #FFD930;

            fill: #666;
            width: 20px;
            height: 20px;
            
            &:hover {
                fill: #333;
            }
        }
    }
} 

.header-accessibility-icons {
    gap: 10px;
}