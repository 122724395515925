.AddToFavoritesModal {
  $mobileContainerPadding: 0 20px 25px 20px;
  $desktopContainerPadding: 0 113px 20px 113px;

  .AddToFavoritesModal__contentContainer {
    padding: $mobileContainerPadding;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    flex: 1;
    overflow: hidden;
  }

  .AddToFavoritesModal__noGroup {
    text-align: center;
  }

  .AddToFavoritesModal__favoritesGroups {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    flex: 1;
    overflow: auto;

    // Scrollbar
    &::-webkit-scrollbar {
      width: 14px;
    }

    &::-webkit-scrollbar-thumb {
      border: 4px solid transparent;
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: #c7c7c7;
    }

    .AddToFavoritesModal__favoritesGroups__groupButton {
      flex-direction: column;
      align-items: flex-start;
      padding: 12px 20px;
      gap: 0;
      flex: 1;
      width: 100%;
      max-width: 320px;

      &:focus,
      &:focus-visible {
        outline: none;
        border: 1px solid $black;
      }

      &.AddToFavoritesModal__favoritesGroups__groupButton--selected {
        background: #21212B1A;
      }

      h5,
      p {
        text-align: left;
      }
    }
  }

  .AddToFavoritesModal__button {
    width: 100%;
    max-width: 320px;

    svg {
      width: 10px;
      height: 10px;
      min-width: 10px;
      min-height: 10px;
    }
  }

  .NewFavoritesGroupButton {
    margin: 25px 0 15px 0;
  }

  @media screen and (min-width: 768px) {
    .AddToFavoritesModal__contentContainer {
      padding: $desktopContainerPadding;
    }

    .AddToFavoritesModal__favoritesGroups {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: stretch;
    }

    .AddToFavoritesModal__button {
      svg {
        width: 12px;
        height: 12px;
        min-width: 12px;
        min-height: 12px;
      }
    }

    .NewFavoritesGroupButton {
      margin: 20px 0 30px 0;
    }
  }
}