/*** GENERIC ***/

.normalWidth{
  margin: 5%;
  padding: 5%;
}

.fullWidth{
  width: 100%;
  padding: 40px;
  margin: 0;
}

.FlexContainer{
  h2{
    margin-bottom: 2em;
  }

  &__content{
    display: flex;
  }
}

/*** MEDIA QUERIES ***/

@media all and (min-width: 375px) {
  .FlexContainer{
    &__content{
      flex-flow: row wrap;
      gap: 1rem;
    }
  }
}

@media all and (min-width: 1440px) {
  .FlexContainer{
    &__content{
      flex-flow: row nowrap;
    }
  }


}
