.ScrollingEngineSuggestions {
  background: #D0DB5F;
  display: flex;
  height: 11vh;
  gap: 10px;
  padding: 10px 0;

  &__list {
    display: flex;
    gap: 20px;

    li {
      &:first-child{
        margin-left: 20px;
      }
    }

    a {
      color: black;
      font-size: 14px;
      background: rgba(255, 255, 255, 0.4);
      padding: 10px;
      border: solid 1px white;
      border-radius: 5px;
      transition: all 0.3s ease;
      font-weight: 700;

      &:hover{
        cursor: pointer;
        background: rgba(255, 255, 255, 0.8);
      }
    }
  }

  @media screen and (min-width: 768px) {
  }

  @media screen and (min-width: 1600px) {
    height: 8vh;
  }
}
