.EngineCard {
  .ButtonV2 {
    width: 100%;
    background: $gray100;
    border: 1px solid $gray100;
    color: $black60;
    border-radius: 3px;

    &:focus,
    &:focus-visible {
      outline: none;
      border: 1px solid $black;
    }

    &:hover {
      background: $black;
      border: 1px solid $black;
      color: $white;
    }

    .ButtonV2__text {
      font-weight: 600;
      text-align: left;
      font-style: italic;
    }
  }
}
