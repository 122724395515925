.ArticlesCarouselLink {
  gap: 10px;

  &:hover {
    text-decoration: underline;

    svg {
      rotate: -45deg;
    }
  }

  svg {
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
    transition: rotate 0.2s ease-in-out;
  }

  @media screen and (min-width: 768px) {
    svg {
      width: 14px;
      height: 14px;
      min-width: 14px;
      min-height: 14px;
    }
  }
}
