.TableStoreItemComp {
  $gap: 15px;

  display: flex;
  flex-direction: column;
  gap: $gap;
  container-type: inline-size;

  .TableStoreItemComp__title {
    font-family: $mainFont;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
  }

  table {
    border-bottom: 1px solid #21212B33;
    border-spacing: 0;

    tbody {
      overflow: hidden;

      tr {
        padding: 14px 15px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 0;

        &:nth-child(1) {
          border-radius: 3px 3px 0 0;
        }

        &:nth-child(odd) {
          background-color: $gray100;
        }

        td {
          flex: 1;
          font-family: $mainFont;
          font-size: 13px;
          line-height: 20px;
          font-weight: 500;
        }

        strong {
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;

          p {
            font-weight: 500;
          }
        }
      }
    }
  }

  @container (min-width: 320px) {
    table {
      tbody {
        tr {
          flex-direction: row;
          align-items: center;
          gap: 20px;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    table {
      tbody {
        tr {
          padding: 19px 20px;

          td {
            font-size: 14px;
            line-height: 24px;

            strong {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}