.CustomImage {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;

  .credit {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    max-width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: white;
      padding: 2px 4px;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 10px;
    }
  }
}