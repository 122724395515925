.AppPopup {
  $mobilePadding: 20px;
  $desktopPadding: 40px;
  $maxWidthMobile: 440px;
  $maxWidthDesktop: 660px;
  $mobileContainerPadding: 59px 20px;
  $desktopContainerPadding: 69px 113px;

  position: fixed;
  z-index: 100000;
  width: 100vw;
  height: 100dvh;
  top: 0;
  left: 0;
  padding: $mobilePadding;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #21212b66;

  .AppPopup__Container {
    width: 100%;
    max-width: $maxWidthMobile;
    padding: $mobileContainerPadding;
    box-shadow: 0 2px 3px 0 #00000026;
    background: $white;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    .AppPopup__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      font-family: $mainFont;
      text-align: center;
      color: $black;

      h4 {
        font-size: 18px;
        line-height: 26px;
        font-weight: 600;
      }

      p {
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
      }
    }

    .AppPopup__options {
      width: 100%;
      max-width: 360px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;

      .ButtonV2 {
        padding: 14px 20px;
        width: 100%;
        justify-content: flex-start;
      }
    }
  }

  @media screen and (min-width: 768px) {
    padding: $desktopPadding;

    .AppPopup__Container {
      max-width: $maxWidthDesktop;
      padding: $desktopContainerPadding;
      gap: 30px;

      .AppPopup__header {
        gap: 30px;

        h4 {
          font-size: 22px;
          line-height: 32px;
        }

        p {
          font-size: 14px;
          line-height: 24px;
        }
      }

      .AppPopup__options {
        .ButtonV2 {
          padding: 14px 24px;

          svg {
            width: 22px;
            height: 22px;
            min-width: 22px;
            min-height: 22px;
          }

          .ButtonV2__text {
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }
  }
}
