.Form {
  $gap: 20px;
  $headerGap: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $gap;
  font-family: $mainFont;

  .Form__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $headerGap;
    text-align: center;
    margin-bottom: 10px;

    .Form__title {
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
    }

    .Form__subtitle {
      font-size: 13px;
      line-height: 20px;
      font-weight: 500;
    }
  }

  .Form__fields {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $gap;
  }

  .FormGroup {
    width: 100%;

    .FormGroup__header {
      padding: 20px 0;

      .FormGroup__title {
        font-size: 14px;
        line-height: 22px;
        font-weight: 600;
      }
    }

    .Separator {
      margin-top: 30px;
    }
  }

  .Form__submitContainer {
    width: 100%;

    .Form__submit {
      width: 100%;

      .ButtonV2__text {
        text-align: center;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .Form__header {
      margin-bottom: 0;
    }

    .FormGroup {
      .FormGroup__header {
        .FormGroup__title {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .Separator {
        margin-top: 50px;
      }
    }

    .Form__submitContainer {
      margin-top: 10px;
    }
  }
}