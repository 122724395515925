.EditFavoritesGroup {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 0;

  .EditFavoritesGroup__item {
    padding: 3px 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    font-size: 13px;
    line-height: 20px;
    font-weight: 700;

    &:hover {
      background: $gray100;
    }

    .EditFavoritesGroup__item__pictoContainer {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        min-width: 20px;
        min-height: 20px;
        width: 20px;
        height: 20px;

        &.cross {
          height: 12px;
          min-height: 12px;
        }

        path {
          stroke: currentColor;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    .EditFavoritesGroup__item {
      font-size: 14px;
      line-height: 24px;
      font-weight: 700;

      .EditFavoritesGroup__item__pictoContainer {
        width: 40px;
        height: 40px;
      }
    }
  }
}