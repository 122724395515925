.loginPopup {
  .popupBlock {
    width: 430px;

    .title {
      font-weight: 450;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      margin: 0 32px 32px;
      color: $client;
    }

    .subtitle {
      font-size: 14px;
      line-height: 17px;
      padding-top: 32px;
      margin: 32px 0 16px;
      border-top: solid 1px $gray300;
    }

    .oAuthButton {
      margin-top: 8px;
    }

    .optin {
      display: flex;
      text-align: left;
      gap: 8px;
      align-items: flex-start;
      margin-bottom: 16px;

      input {
        margin-top: 2px;
      }
    }
  }
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: $black25;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  .popupBlock {
    background: $white;
    border-radius: 25px;
    padding: 32px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    max-width: 90%;

    .close {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }
}