.Modal {
  $mobilePadding: 20px;
  $desktopPadding: 40px;
  $maxWidthMobile: 440px;
  $maxWidthDesktop: 886px;
  $mobileHeaderPadding: 40px 20px 0 20px;
  $desktopHeaderPadding: 50px 113px 0 113px;

  position: fixed;
  z-index: 100000;
  width: 100vw;
  height: 100dvh;
  top: 0;
  left: 0;
  padding: $mobilePadding;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #21212B66;

  .Modal__container {
    position: relative;
    width: 100%;
    max-width: $maxWidthMobile;
    max-height: 100%;
    box-shadow: 0 2px 3px 0 #00000026;
    background: $white;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    .Modal__closeButton {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 40px;
      height: 40px;

      svg {
        width: 10px;
        height: 10px;
        min-width: 10px;
        min-height: 10px;
      }
    }

    .Modal__header {
      padding: $mobileHeaderPadding;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: $mainFont;
      color: $black;
      text-align: center;
    }

    h4 {
      font-size: 18px;
      line-height: 26px;
      font-weight: 600;
    }

    h5 {
      font-size: 14px;
      line-height: 22px;
      font-weight: 600;
    }

    p {
      font-size: 13px;
      line-height: 20px;
      font-weight: 500;
    }
  }

  @media screen and (min-width: 768px) {
    padding: $desktopPadding;

    .Modal__container {
      max-width: $maxWidthDesktop;
      gap: 30px;

      .Modal__header {
        padding: $desktopHeaderPadding;
      }

      h4 {
        font-size: 22px;
        line-height: 32px;
      }

      h5 {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
      }

      p {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}