.carousel {
  margin: 32px 0 10px 24px;
  overflow: hidden;
  padding: 10px 0;
  position: relative;

  .header {
    font-size: 66px;
    margin: 0 0 10px 15px;
    font-family: $titleFont;
    line-height: 32px;

    &.with-button {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      .carousel-header{
        grid-column: 2;
      }

      button {
        grid-column: 3;
        padding: .8rem 1.2rem;
        transition: all .3s ease;
        justify-self: end;
      }
    }

    span {
      display: block;
      font-family: $mainFont;
      font-weight: 700;
      font-size: 25px;
      text-transform: uppercase;
      color: $pro;
    }
  }

  .scrollpart {
    display: flex;
    width: 100%;
    overflow: auto;
  }

  .pageButton {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: white;
    height: 44px;
    width: 44px;
    border-radius: 22px;
    align-items: center;
    justify-content: center;
    &.previous {
      left: -22px;
    }
    &.next {
      right: -22px;
    }
  }

  @media screen and (min-width: 768px) {
    padding: 24px 85px;
    margin: 76px 0 10px;

    .header {
      font-size: 89px;
      line-height: 43px;
      text-align: center;
      margin: 0 0 30px;

      span {
        display: inline;
        font-size: 34px;
        margin-left: 10px;
      }
    }

    .pageButton {
      display: flex;
    }
  }
}

.offerSlide {
  width: 264px;
  min-width: 264px;
  margin: 6px;
  position: relative;

  &>div {
    margin-top: 5px;
  }

  img {
    height: 264px;
    object-fit: cover;
    margin-bottom: 10px;
  }

  &.small {
    width: 234px;
    min-width: 234px;
    img {
      height: 124px;
    }
  }

  .category {
    font-size: 12px;
    font-weight: 700;
  }

  .title {
    font-size: 18px;
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
  }

  .place {
    color: $black60;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;

    svg {
      height: 10px;
      width: 8px;
      margin-right: 6px;

      path {stroke: $black60}
    }
  }

  .discountBlock {
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .discount {
      background: $yellow;
      font-size: 13px;
      font-weight: 700;
      padding: 16px 26px 12px 11px;
      border-radius: 0 40px 40px 0;
      margin-top: 10px;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 200px;
    }
  }

  @media screen and (min-width: 450px) {
    width: 352px;
    min-width: 352px;
    margin: 8px 10px;

    img {
      height: 352px;
      margin-bottom: 10px;
    }

    &.small {
      width: 232px;
      min-width: 232px;

      img {
        height: 232px;
      }
    }

    .discountBlock {
      .discount {
        font-size: 13px;
        font-weight: 700;
        padding: 16px 26px 12px 11px;
        border-radius: 0 20px 20px 0;
      }
    }
  }
}

.portraitCarousel {
  position: relative;

  &.withBackground {
    padding: 10px 0;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .header {
    color: $pro;

    span {
      color: $black;
    }
  }

  &.withBackground {
    .header span {
      color: $white;
    }
  }

  @media screen and (min-width: 768px) {
    &.withBackground {
      padding: 1px 0 10px;

      .background {
        top: 50px;
        height: calc(100% - 50px);
      }
    }
  }
}

.portraitSlide {
  width: 265px;
  min-width: 265px;
  height: 219px;
  margin: 6px;
  position: relative;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    filter: brightness(0.5);
  }

  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30%;
  }

  .name, .description, .quote {
    position: absolute;
    color: $white;
    left: 0;
    right: 0;
    text-align: center;
  }

  .name {
    top: 0;
    font-weight: 700;
    font-size: 24px;
  }
  .description {
    top: 40%;
    font-size: 15px;
    margin: 0 20px;
  }
  .quote {
    top: 0;
    height: 70%;
    padding-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-style: italic;
    margin: 0 20px;

    svg {
      position: absolute;
      top: 30px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  @media screen and (min-width: 450px) {
    width: 500px;
    min-width: 500px;
    height: 414px;
    margin: 8px;

    .quote {
      top: 0;
      font-size: 40px;
      font-style: italic;
      margin: 0 60px;
    }
  }
}

.searchCarousel {
  .header {
    font-size: 25px;
    margin: 0 10px 10px;
    line-height: 32px;
    text-align: left;
    font-family: $mainFont;
    font-weight: bold;
  }

  .carousel {
    margin: 10px 0;
  }

  @media screen and (min-width: 768px) {
    .carousel {
      padding: 24px 25px;
    }
    .header {
      font-size: 30px;
      margin: 0 10px 20px;
    }
  }
}

.mediaCarousel {
  .carousel {
    margin: 0;

    .header {
      color: $white;
      line-height: 40px;

      span {
        color: $black;
      }
    }

    .scrollpart {
      gap: 30px;
      padding: 0 20px;
    }
    .slide {
      .content {
        max-height: 315px;
        max-width: 560px;
        width: calc(100vw - 80px);
        height: calc((100vw - 80px) * (315/560));
        position: relative;
      }
      .label {
        color: $white;
        font-size: 14px;
        text-align: center;
        margin-top: 5px;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .carousel {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 30px;
      padding: 65px 65px 40px;

      .header {
        width: 300px;
        line-height: 59px;

        span {
          display: block;
        }
      }

      .scrollBloc {
        position: relative;
        width: calc(100% - 330px);
      }
    }
  }
}

/********** SearchMapCarousel **********/

.searchMapCarousel {
  header{
    display: flex;
    justify-content: space-between;
  }
  .carousel-button{
    width: auto!important;
    padding: 0.8rem 1rem!important;
    margin: 0!important;
  }
  .starNote, .place{
    display: none;
  }
  .offerSlide {
    min-width: 200px;
    width: auto;

    .title{
      font-weight: 500;
    }

    img{
      width: 200px;
      height: 200px;
      object-fit: contain;
      border-radius: 10px;
    }
  }
}

/*** Articles Carousel ***/

.Articles{

  .offerSlide {
    width: 250px;
    min-width: 250px;
    margin: 6px;
    position: relative;

    img {
      height: 200px;
      object-fit: cover;
      margin-bottom: 10px;
    }
  }
}

@media screen and (min-width: 450px) {

  .Articles{

    .offerSlide {
      width: 250px;
      min-width: 250px;
      margin: 8px 10px;

      img {
        height: 200px!important;
        margin-bottom: 10px;
      }
    }
  }

}

/*** Edito Carousel ***/


.editoCarousel {

  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media all and (min-width: 768px){
    flex-direction: row;
  }

  &__header {
    text-align: center;
    width: 100%;

    @media all and (min-width: 768px){
      width: 40%;
    }

    @media all and (min-width: 1024px){
      width: 40%;
    }

    @media all and (min-width: 1440px){
      width: 30%;
    }

    @media all and (min-width: 1600px){
      width: 30%;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 2rem;
  }
  &__content{
    position: relative;
    width: 100%;

    @media all and (min-width: 768px){
      width: 60%;
    }

    @media all and (min-width: 1024px){
      width: 60%;
    }

    @media all and (min-width: 1440px){
      width: 70%;
    }

    display: flex;
  }

  .embla__slide__background-container {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--overlay-color, rgba(0, 0, 0, 0.2));
      cursor: grab;
    }
  }

  /*** Full Screen ***/

  &--fullScreen{
    flex-direction: column;
    align-items: center;

    padding: 2rem 0;

    @media all and (min-width: 1600px){
      padding: 4rem;
    }

    h3 {
      font-size: 2rem;
    }

    &__header{
      width: 100%;
      display: flex;
      flex-flow: column;
      align-items: center;
    }

    &__content{
      position: relative;
      margin: 10px 30px;

      @media all and (min-width: 768px){
        margin: 40px 30px;
      }
    }

    .embla{
      overflow: hidden;
    }

    .embla__container {
      display: flex;
      gap: 1rem;
    }

    .embla__prev,
    .embla__next{
      background-color: var(--white);
      border-radius: 50%;
      width: 40px;
      height: 40px;
      padding: 1em;
      box-shadow: 0px 2px 3px 0px #00000026;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 20;
    }

    .embla__prev{
      left: -20px;
    }

    .embla__next{
      right: -20px;
      svg{
        transform: rotate(180deg);
        }
    }

    .embla__slide {
      padding: 2rem 1rem;
      flex: 0 0 100%;
      position: relative;
      align-items: center;

      @media all and (min-width: 768px){
        flex: 0 0 80%;
      }
      min-width: 0;
      min-height: 400px;
      margin: 20px;
      @media all and (min-width: 768px){
        min-height: 450px;
      }
      @media all and (min-width: 1024px){
        min-height: 650px;
      }
      @media all and (min-width: 1440px){
        min-height: 800px;
      }
      width: 100%;
      border-radius: 5px;
      background-size: cover;
      background-position: center;
      display: flex;
      justify-content: center;
      // align-items: end;
      position: relative;
      color: $white;
      overflow: hidden;

      &__background-container {
        &::after{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          cursor: grab;
          background-color: var(--overlay-color, rgba(0, 0, 0, 0.2));
        }
      }
      &:active{
        cursor: grabbing;
      }


      &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        object-fit: cover;


      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        gap: 1rem;

        .embla__numbers {
          text-align: center;
          font-weight: 700;
          margin-bottom: 1rem;
        }

        .embla_slide_title, .embla_slide_subtitle {
          z-index: 2;
          text-align: center;
        }

        a {
          display: inline-flex;
          justify-content: center;
          cursor: pointer;
        }

        .embla_slide_title {
          font-size: 2rem;
          font-weight: 600;
        }

        .embla_slide_subtitle {
          position: relative;
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: 500;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -5px;
            width: 100%;
            height: 1px;
            background-color: white;
            z-index: -1;
            transition: width 0.3s ease;
          }

          &:hover::after {
            width: 0;
          }
        }

        @media (min-width: 768px) {
          .embla__slide {
            flex: 0 0 50%;
          }
        }

        @media (min-width: 1024px) {
          .embla__slide {
            flex: 0 0 33.33%;
          }
        }
      }

      .FavoriteButton {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 2;
        color: $white;
      }
    }
  }

  /*** Default ***/

  &__carousel {
    width: 100%;
  }

  .embla {
    overflow: hidden;
  }

  .embla__container {
    display: flex;
    gap: 1rem;
  }

  .embla__slide {
    flex: 0 0 100%;
    min-width: 0;
    height: 400px;

    @media all and (min-width: 768px){
      height: 430px;
    }

    margin: 10px;
    width: 100%;
    padding: 2rem 1rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    // align-items: end;
    align-items: center;
    position: relative;
    color: $white;
    // transition: transform 0.3s ease;
    overflow: hidden;
    cursor: grab;

    &__background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      object-fit: cover;
      & img {
        transform: scale(1.1);

      }
      &:hover{
        transition: transform 3s ease;
      }
    }
    &:active{
      cursor: grabbing;
    }


    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: inherit;
      background-size: cover;
      background-position: center;
      transition: transform 0.3s ease;
      border-radius: 5px;
    }

/*    &:hover {
      cursor: grab;
      &::before {
        transform: scale(1.1);
      }
    }*/

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      z-index: 1;
      gap: 1rem;

      .embla_slide_title, .embla_slide_subtitle{
        z-index: 2;
        text-align: center;
      }

      a {
        display: inline-flex;
        justify-content: center;
        cursor: pointer;
      }

      .embla_slide_title {
        font-size: 1.3rem;
        font-weight: 600;
      }

      .embla_slide_subtitle {
        position: relative;
        font-size: 1rem;
        line-height: 1.5rem;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -5px;
          width: 100%;
          height: 2px;
          background-color: white;
          z-index: -1;
          transition: width 0.3s ease;
        }

        &:hover::after {
          width: 0;
        }
      }
    }
  }

  .embla__prev,
  .embla__next{
    background-color: var(--white);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 1em;
    box-shadow: 0px 2px 3px 0px #00000026;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
  }

  .embla__prev{
    left: -20px;
    @media all and (min-width: 1400px){
      left: -10px;
    }
    @media all and (min-width: 1920px){
      left: -20px;
    }
  }

  .embla__next{
    right: -20px;
    @media all and (min-width: 1400px){
      right: -5px;
    }
    @media all and (min-width: 1920px){
      right: -20px;
    }
    svg{
      transform: rotate(180deg);
      }
  }


  @media (min-width: 768px) {
    .embla__slide {
      flex: 0 0 80%;
    }
  }

  @media (min-width: 1400px) {
    .embla__slide {
      flex: 0 0 30%;
    }
  }

  @media (min-width: 1920px) {
    .embla__slide {
      flex: 0 0 18%;
    }
  }

}
