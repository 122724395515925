:root {
  --arrow-icon: url("data:image/svg+xml,%3Csvg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.33545 12.5L7.33545 7L1.33545 1.5' stroke='%2321212B' stroke-opacity='0.4' stroke-width='2'/%3E%3C/svg%3E");
}

.InteractiveMap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex-direction: column;
  @media all and (min-width: 1024px) {
    flex-direction: row;
  }

  &__map {
    flex: 1;
    height: 100%;
    width: 100%;
    padding-right: 8px;

    &__svg-container {
      width: 100%;
      height: auto;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__description {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    @media all and (min-width: 768px) {
      padding: 5%;
    }

    @media all and (min-width: 1024px) {
      padding: 0 5%;
      gap: 0px;
      width: 40%;
    }

    @media all and (min-width: 1200px) {
      padding: 0 2%;
      width: 35%;

      p,
      ul li {
        font-size: 0.95rem;
      }
    }

    @media all and (min-width: 1400px) {
      padding: 0 5%;

      p,
      ul li {
        font-size: 0.1rem;
      }
      gap: 20px;
    }

    @media (min-width: 1600px) {
      width: 25%;
      p,
      ul li {
        font-size: 1.2rem;
      }
    }

    p,
    ul li {
      font-size: 1rem;
    }

    p {
      font-weight: 600;
      color: #21212b;
      line-height: 1.5;
      padding: 15px 20px;
      text-align: left;
      @media (min-width: 768px) {
        padding: 0;
      }
    }

    ul {
      border-top: 1px solid rgba(33, 33, 43, 0.1);
      @media (min-width: 768px) {
        border: none;
      }
      li {
        list-style-type: none;
        margin: 10px 0;

        @media (min-width: 1200px) {
          margin: 20px 0;
        }
        color: var(--dark-100, #21212b);

        font-weight: 500;
        transition: transform 0.3s ease;
        position: relative;
        text-align: left;
        padding: 15px 20px;
        border-bottom: 1px solid rgba(33, 33, 43, 0.1);
        @media (min-width: 768px) {
          border: none;
          padding: 0 20px 0 0;
          font-size: 1rem;
          // color: var(--dark-40, rgba(33, 33, 43, 0.4));
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;

          width: 9px;
          height: 14px;

          background: {
            image: var(--arrow-icon);
            repeat: no-repeat;
            position: center;
          }

          transform: translateY(-50%);
        }

        &:hover {
          transform: translateX(10px);
          color: var(--dark-100, #21212b);
        }
      }
    }
  }
}

.InteractiveMap {
  &__map {
    path {
      transition: all 0.3s ease;
    }

    // Animation sur le chemin actif
    path[style*='stroke: #'] {
      stroke-dasharray: 1000;
      stroke-dashoffset: -1000;
      animation: fillPathReverse 1.5s ease forwards;
    }
  }
}

@keyframes fillPathReverse {
  from {
    stroke-dashoffset: -1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.Carte_svg__city-link {
  cursor: pointer;

  path {
    stroke-width: 20px; // Zone de clic plus large
    stroke: transparent; // Invisible
    paint-order: stroke; // Le stroke passe derrière le fill
  }

  &:hover path {
    opacity: 0.7;
  }
}

.Carte_svg__st4,
.Carte_svg__st6,
.Carte_svg__st8 {
  fill: none;
}
.Carte_svg__st9 {
  fill: #c5d571;
}
.Carte_svg__st10,
.Carte_svg__st4,
.Carte_svg__st6 {
  stroke-width: 2px;
}
.Carte_svg__st4,
.Carte_svg__st6,
.Carte_svg__st8 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.Carte_svg__st10,
.Carte_svg__st12 {
  fill: #d0db5f;
}
.Carte_svg__st13 {
  fill: #7b31af;
}
.Carte_svg__st14 {
  fill: #f4f4f4;
}
.Carte_svg__st15 {
  fill: #dba070;
}
.Carte_svg__st16 {
  fill: #bcbf86;
}
.Carte_svg__st10,
.Carte_svg__st8 {
  stroke: #fff;
}
.Carte_svg__st10 {
  stroke-miterlimit: 10;
}
.Carte_svg__st10,
.Carte_svg__st18 {
  display: none;
}
.Carte_svg__st19 {
  fill: #b03030;
}
.Carte_svg__st4 {
  stroke: #b03030;
}
.Carte_svg__st6 {
  stroke: #ceced9;
}
.Carte_svg__st21 {
  fill: #c4e7df;
}
.Carte_svg__st23 {
  fill: #f7ac6f;
}
.Carte_svg__st24 {
  fill: #3d8ccc;
}
