.Select {
  $padding: 11px 15px;
  $gap: 10px;
  $iconSize: 20px;

  font-family: $mainFont;

  .Select__control {
    background: $white;
    border: 1px solid #21212B33;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $gap;

    &:focus,
    &:focus-within {
      border-color: $black;
    }

    &:hover {
    cursor: pointer;
    }
  }

  .Select__value-container {
    padding: $padding;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $gap;
  }

  .Select__value,
  .Select__option {
    svg {
      min-width: $iconSize;
      min-height: $iconSize;
      width: $iconSize;
      height: $iconSize;

      &.envelopeV2,
      &.facebookV2,
      &.instagramV2,
      &.youtubeV2 {
        path {
          fill: none;
        }

        rect,
        path {
          stroke: currentColor;
        }
      }

      path,
      ellipse,
      line {
        stroke: currentColor;
      }
    }
  }

  input {
    position: absolute;
  }

  .Select__value {
    display: flex;
    align-items: center;
    gap: $gap;

    span {
      font-size: 13px;
      line-height: 20px;
      font-weight: 700;
    }
  }

  .Select__option {
    padding: $padding;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: $gap;

    &:hover {
      background: $gray100;
    }

    span {
      font-size: 13px;
      line-height: 20px;
      font-weight: 500;
    }
  }

  .Select__dropdown-indicator {
    padding: $padding;
  }

  @media screen and (min-width: 768px) {
  }
}
