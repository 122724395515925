.Button {
  cursor: pointer;
  padding: 12px 35px;
  border-radius: 2em;
  background: $green-light;
  color: $white;
  border: 1px solid $white;
  font-size: 12px;
  line-height: 1;
  font-family: $mainFont;
  font-weight: bold;
  text-align: center;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  &:hover {
    background: $white;
    color: $green-light;
    border: 1px solid $green-light;
  }

  &:disabled {
    cursor: default;
    pointer-events: none;
  }

  &.white {
    background: $white;
    color: $client;
    border: 1px solid $client;

    &:hover {
      background: $client;
      color: $white;
      border: 1px solid $white;
    }
  }

  &.pro {
    background: $pro;
    color: $white;
    border: 1px solid $white;

    &:hover {
      background: $white;
      color: $pro;
      border: 1px solid $pro;
      path, circle {
        stroke: $pro;
        fill: $pro;
      }
    }
  }

  &.yellow {
    background: $yellow;
    color: $black;
    border: 1px solid $white;

    &:hover {
      background: $white;
      color: $black;
      border: 1px solid $yellow;
    }
  }

  &.client {
    background: $client;
    color: $white;
    border: 1px solid $white;

    path, circle {
      stroke: $white;
    }

    &:hover {
      background: $white;
      color: $client;
      border: 1px solid $client;
      path, circle {
        stroke: $client;
      }
    }
  }

  &.transparent {
    background: transparent;
    color: $black;
    border: 1px solid $black;

    &:hover {
      background: $white;
    }
  }
}


.svgButton {
  gap: 2px!important;
  border: none !important;
  background: transparent !important;
  position: relative;
  min-width: 180px;
  height: 50px;
  border: none;
  background: none;
  padding: 0 10px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    stroke: none;
    transform: none!important;
    path {
      transition: all .4s ease-in-out;
      stroke: none!important;
    }
  }

  #caretRight, svg:nth-of-type(1) {
    fill:white;
    z-index:3;
  }

  &:disabled {
    svg:last-child {
      fill:var(--travelPlanerSearchMapInactiveBackground)!important;
    }
  }

  svg:last-child {
    position: absolute;
    top: 0;
    left: 0!important;
    width: 100%!important;
    height: 100%!important;


    path {
      fill: var(--travelPlanerSearchMapBackground)!important;
    }
  }

  .labelText {
    z-index:4;
    color: #FFF;
    min-width: 90px;
    font-weight: 500;
    text-align: center;
    padding: 8px;
    font-family: var(--travelPlanerSubtitleFont, Arial, sans-serif);
    font-style: italic;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.36px;
    white-space: nowrap;
    font-size: .9rem;
  }
}

button.svgButton {
  svg{
    left: 0!important;
    transition: all 0.4s ease-in-out;

  }
  position: relative;
  svg path{
    stroke: none!important;
  }

  &:disabled {
    svg:last-child {
      path{
        fill: var(--travelPlanerSearchMapInactiveBackground)!important;
      }
    }
  }
}

button.svgButton.secondary {
  svg:last-child {
    path {
      opacity: 0.7;
      fill: var(--travelPlanerSecondaryColor)!important;
    }
  }
  &:hover {
    svg:last-child {
      path{
        fill: var(--travelPlanerSecondaryColor)!important;
        opacity: 1;
      }
    }
  }
}


button.svgButton.next, button.svgButton.back, button.svgButton {
  &:hover {
    svg:last-child {
      path{
        fill: var(--travelPlanerSearchMapActiveBackground)!important;
      }
    }
  }
}

.svgButton .labelText {
  font-weight: bold;
  font-style: normal;
}

.d-none {
  display: none;
}