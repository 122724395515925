.ContentShowcase {
  position: relative;
  width: 100%;
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  height: fit-content;

  .informations {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .title {
      transition: translate 400ms;

      &:hover {
        translate: 10px 0;
      }

      h2 {
        font-family: $mainFont;
        font-size: 2.25rem;
        color: #3b3b3b;
      }
    }

    .links,
    .ctas {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      transition: translate 400ms;

      .link {
        display: flex;
        align-items: center;
        gap: 5px;
        line-height: 1.15;
        font-weight: 700;
        transition: all 300ms;

        &:hover {
          translate: 10px 0;
        }

        svg {
          width: 14px;
          height: 14px;
        }
      }
    }

    .links {
      gap: 5px;

      .link {
        &:hover {
          color: #9bb044;
        }

        svg {
          path {
            fill: $black;
          }
        }
      }
    }

    .ctas {
      gap: 10px;

      .link {
        gap: 10px;
        background: $black;
        color: $white;
        padding: 10px 20px;
        border: 1px solid $black;

        &:hover {
          opacity: 0.9;
        }

        svg {
          path {
            fill: $white;
          }
        }
      }
    }

    .description {
      p {
        line-height: 1.5;
        font-size: 1rem;
        font-weight: 300;
      }
    }
  }

  .images {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .image-container {
      .image {
        display: block;
        position: relative;
        // border-radius: 10px;
        overflow: hidden;

        &:hover {
          .overlay {
            top: 0;
            opacity: 1;
          }

          .text {
            opacity: 1;
            visibility: visible;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .overlay {
          position: absolute;
          top: 20px;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          opacity: 0;
          transition: top 300ms, opacity 200ms;
        }

        .text {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 20px;
          opacity: 0;
          visibility: hidden;
          transition: opacity 300ms;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 15px;
          color: $white;
          text-align: center;

          .title {
            font-weight: 700;
            font-size: 1rem;
          }

          .subtitle {
            font-weight: 300;
            font-size: 1.15rem;
            line-height: 1.5;
          }
        }
      }
    }
  }

  @media screen and (min-width: 320px) {
    justify-content: center;

    .images-wrapper {
      max-width: 480px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto auto 55px auto;

      .images {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(7, 1fr);
        max-height: 750px;

        .image-container {
          &:nth-child(1) {
            grid-area: 1 / 1 / 5 / 2;

            .image {
              margin-left: auto;
            }
          }

          &:nth-child(2) {
            grid-area: 5 / 1 / 8 / 2;
            height:100%;
          }

          &:nth-child(3) {
            grid-area: 1 / 2 / 4 / 3;
            display: flex;
            align-items: flex-end;

            .image {
              height: 90%;
            }
          }

          &:nth-child(4) {
            grid-area: 4 / 2 / 8 / 3;

            .image {
              width: 75%;
            }
          }

          .image {
            width: 100%;
            height: 100%;

            .text {
              padding: 10px;
              word-break: break-word;

              .title {
                font-size: 2.5vw;
              }

              .subtitle {
                font-size: 3vw;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    flex-direction: row-reverse;
    align-items: flex-start;
    padding: 60px 85px;
    gap: 85px;

    .informations {
      position: sticky;
      top: 40px;
      gap: 30px;
    }

    .images-wrapper {
      position: sticky;
      top: 40px;
      max-width: 45%;
      margin: 0 auto;

      .images {
        .image-container {
          .image {
            .text {
              padding: 20px;

              .title {
                font-size: 1.25vw;
              }

              .subtitle {
                font-size: 1.5vw;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .images-wrapper {
      .images {
        .image-container {
          .image {
            .text {
              .title {
                font-size: 1.25rem;
              }

              .subtitle {
                font-size: 1.5rem;
              }
            }
          }
        }
      }
    }
  }
}
