.RenameFavoritesGroupModal {
  $mobileContainerPadding: 0 20px 40px 20px;
  $desktopContainerPadding: 0 113px 50px 113px;

  .Form {
    padding: $mobileContainerPadding;
    width: 100%;

    .Form__submit {
      width: 100%;
      max-width: 320px;
      margin: 0 auto;

      svg {
        width: 10px;
        height: 10px;
        min-width: 10px;
        min-height: 10px;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .Form {
      padding: $desktopContainerPadding;

      .Form__submit {
        svg {
          width: 12px;
          height: 12px;
          min-width: 12px;
          min-height: 12px;
        }
      }
    }
  }
}