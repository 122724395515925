.ArticleCard {
  $mobileContentPadding: 30px 20px;
  $desktopContentPadding: 40px 30px;
  $mobileContentGap: 15px;

  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 280 / 380;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: all 0.3s 0.1s ease-in-out;
  }

  &__content {
    z-index: 1;
    padding: $mobileContentPadding;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: $mobileContentGap;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 25%,
        rgba(0, 0, 0, 0.6) 100%
    );

    font-family: $mainFont;
    text-align: center;
    color: $white;

    span {
      font-size: 18px;
      line-height: 26px;
      font-weight: 600;
    }

    .ButtonV2 {
      text-align: center;
      color: $white;
      border-radius: 0;
      border-bottom: 1px solid $white;
      text-decoration: none !important;
    }
  }

  .FavoriteButton {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    color: $white;
  }

  @media screen and (min-width: 1024px) {
    aspect-ratio: 433 / 540;
  }
}
