.InteractiveContent {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: left;
  align-items: center;
  margin: 70px 20px 0;
  @media all and (min-width: 768px) {
    flex-direction: row;
    margin: 100px 5% 0 10%;


  }

  &__text__container {
    width: 100%;
    padding-bottom: 50px;
    @media all and (min-width: 768px) {
      width: 423px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    padding-bottom: 20px;
    @media all and (min-width: 768px) {
      padding-bottom: 30px;

    }
  }

  &__text {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;

  }

  &__images {
    position: relative;
    display: flex;
    justify-content: center;
    width: 360px;
    @media all and (min-width: 768px) {
      width: 631px;
    }


    & img {
      border-radius: 1.52px
    }

    & img:nth-child(1) {
      width: 161px;
      height: 228px;
      z-index: 2;

      @media all and (min-width: 768px) {
        width: 319px;
        height: 450px;
      }


    }

    & img:nth-child(2), & img:nth-child(3) {
      position: absolute;

      width: 105px;

      @media all and (min-width: 768px) {
        width: 207px;
        height: 207px;
      }
    }

    & img:nth-child(2) {
      left: 0;
      top: 20px;
      z-index: 3;

    }

    & img:nth-child(3) {
      right: 0;
      bottom: 20px;
      z-index: 1;

    }
  }
}
