.heroResult {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 204px;

  .background {
    position: absolute;
    object-position: center;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .container {
    position: absolute;
    bottom: 10%;
    left: 20px;
    right: 20px;
    color: $white;
    h2 {
      font-size: 30px;
    }
  }

  @media screen and (min-width: 768px) {
    .container {
      left: 40px;
      right: 40px;
    }
  }

  @media screen and (min-width: 1024px) {
    height: 237px;

    .container h2 {
      font-size: 40px;
    }
  }
}
