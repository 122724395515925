.HeroSuggestions {
  height: 100dvh;
  display: flex;
  justify-content: center;
  position: relative;

  &--small {
    height: 60dvh;
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
      margin-top: 0 !important;
    }

    .copyright-container {
      bottom: 20px !important;
    }
  }

  &__copyright {
    bottom: 120px;

    .copyright-symbol {
      width: 10px;
    }
  }

  &__background__overlay,
  &__background__image {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__background__image {
    z-index: 0;
    object-fit: cover;
  }

  h1,
  h2 {
    font-family: 'Montserrat', sans-serif;
    color: white;
    text-align: center;

    @media all and (max-width: 768px) {
      padding: 0 7%;
    }
  }

  h1 {
    text-transform: initial;
    @media all and (max-width: 424px) {
      font-size: 14px;
    }
    font-size: 16px;
    font-weight: 600;
  }

  h2 {
    @media all and (min-width: 1200px) {
      font-size: 3rem;
    }
    font-size: 2rem;
    font-weight: 700;
  }

  .container {
    margin-top: 30dvh;
    position: relative;

    @media all and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media (min-width: 1600px) {
      margin-top: 35dvh;
    }
  }

  .ScrollingEngineSuggestions {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 3;
  }

  .searchBoxIA {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    width: 100%;
    @media all and (max-width: 768px) {
      padding: 0;
    }
    padding: 0 24px;

    .searchBoxIA-form {
      background: white;
      width: 100%;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      gap: 12px;
      border-radius: 25px;

      .searchBoxIA-input {
        width: 100%;
        display: flex;
        align-items: flex-start;
        resize: none;
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;

        @media screen and (min-width: 768px) {
          font-size: 16px;
          font-weight: 700;
        }

        &::placeholder {
          font-family: 'Montserrat';
          font-weight: 500;
          line-height: 24px;
          @media screen and (min-width: 768px) {
            font-weight: 600;
          }
        }
      }
    }

    .searchBoxIA-form-focused {
      @extend .searchBoxIA-form;
      outline: 1px solid var(--Dark, #21212b);
    }
  }

  .copyright-container {
    position: absolute;
    bottom: 120px;
    left: 20px;
    z-index: 10;

    .copyright-circle {
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      border-radius: 15px;
      display: flex;
      align-items: center;
      height: 30px;
      padding: 0 10px;
      cursor: pointer;
      transition: width 0.5s cubic-bezier(0.4, 0, 0.2, 1);
      width: 30px;
      overflow: hidden;
      white-space: nowrap;

      .copyright-symbol {
        font-size: 14px;
      }

      .copyright-text {
        font-size: 12px;
        opacity: 0;
        margin-left: 8px;
        transform: translateX(-10px);
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
      }

      &:hover {
        // width: 200px;

        .copyright-text {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }
}
