.ArticlesCarouselLabel {
  $mobileGap: 8px;
  $desktopGap: 10px;

  display: flex;
  align-items: center;
  gap: $mobileGap;
  color: $white;

  svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;

    svg {
      path {
        fill: currentColor;
      }
    }
  }

  .ArticlesCarouselLabel__text {
    font-family: $mainFont;
    font-size: 13px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: 0.02em;
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    gap: $desktopGap;

    svg {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
    }

    .ArticlesCarouselLabel__text {
      font-family: $mainFont;
      font-size: 14px;
      line-height: 24px;
      font-weight: 700;
      letter-spacing: 0.02em;
      text-align: center;
    }
  }
}
