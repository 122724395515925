.ContainerShopV2 {
  $paddingMobile: 30px 20px;
  $paddingDesktop: 40px 30px;
  $gapMobile: 15px;
  $gapDesktop: 20px;

  padding: $paddingMobile;
  display: flex;
  flex-direction: column;
  gap: $gapMobile;
  background: $white;
  border: 1px solid #21212B33;
  border-radius: 3px;
  text-align: left;

  &__title {
    font-family: $mainFont;
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
  }

  @media screen and (min-width: 768px) {
    padding: $paddingDesktop;
    gap: $gapDesktop;

    &__title {
      font-size: 22px;
      line-height: 32px;
    }
  }
}