.FavoritesGroupComp {
  font-family: $mainFont;

  .FavoritesGroupComp__header {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h5 {
      font-size: 14px;
      line-height: 22px;
      font-weight: 600;
    }
  }

  .FavoritesGroupComp__cards {
    width: 100%;
    padding: 0 0 20px 0;

    .DefaultCarousel {
      &--1 {
        .ShopV2Card {
          max-width: 320px;
        }

        .ArticleCard {
          max-width: 433px;
        }
      }

      .DefaultCarousel__carousel__pagination {
        padding: 0 20px 0 20px;
      }

      .swiper-slide {
        &:nth-child(odd) {
          .FavoriteCard {
            padding: 0 10px 0 20px;
          }
        }

        &:nth-child(even) {
          .FavoriteCard {
            padding: 0 20px 0 10px;
          }
        }
      }
    }
  }

  .FavoritesGroupComp__empty {
    padding: 0 20px 20px 20px;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
  }

  @media screen and (min-width: 768px) {
    .FavoritesGroupComp__header {
      padding: 20px 0 30px 0;

      h5 {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .FavoritesGroupComp__cards {
      padding: 0 0 40px 0;
    }

    .FavoritesGroupComp__empty {
      padding: 0 0 40px 0;
      font-size: 14px;
      line-height: 24px;
    }
  }
}