.GroupsShopV2__items {
  $mobileGap: 15px;
  $desktopGap: 20px;
  $gap: 30px;

  display: flex;
  flex-direction: column;
  gap: $mobileGap;

  @media screen and (min-width: 768px) {
    gap: $desktopGap;
  }

  &__map {
    width: 100%;
    aspect-ratio: 280 / 220;
    border-radius: 3px;
    overflow: hidden;
  }

  @container (min-width: 360px) {
    &__details {
      flex-direction: row;
      flex-wrap: wrap;

      .DetailShopV2 {
        width: calc(50% - #{$gap / 2});
      }
    }
  }
}
