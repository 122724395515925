.floatingSearchBar-modal {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 1002;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 30px;
}

.floatingSearchBar-modal-focused {
  @extend .floatingSearchBar-modal;
  background: linear-gradient(
                  rgba(255, 255, 255, 0) 85%, rgba(33, 33, 43, 0.5) 100%);
}

.floatingSearchBar-container {
  bottom: 30px;
  border-radius: 35px;
  background-color: white;
  border: 4px solid rgba(33, 33, 43, 0.1);
  transition: margin 1.1s ease;
  width: 100%;
  margin: 0 5%;
  @media screen and (min-width: 768px) {
    margin: 0 23%;
  }


  input {
    color: rgba(33, 33, 43, 1);
    font-size: .9rem;
    font-weight: 700;
    line-height: 24px;
    padding: 6px 6px 6px 20px;
    width: 100%;

    &::placeholder {
      color: rgba(33, 33, 43, 0.6);
    }
  }
}

.floatingSearchBar-container-focused {
  @extend .floatingSearchBar-container;
  margin: 0 5%;
  @media screen and (min-width: 768px) {
    margin: 0 17%;
  }
}


.floatingSearchBar-content {
  height: 50px;
  background-color: white;
  border-radius: 25px;
  pointer-events: auto;
  display: flex;
  align-items: center;
  padding: 7px 5px;
  border: 1px solid rgba(33, 33, 43, 0.2);
}

.floatingSearchBar-content-focused {
  @extend .floatingSearchBar-content;

  border: 1px solid rgba(33, 33, 43, 1);

}

.floatingSearchBar-button {
  width: 36px;
  height: 36px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
}

.floatingSearchBar-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}



.floatingSearchBar-container {
  transform: translateY(-25%);
  transition: transform 3s ease-in-out;
}

.floatingSearchBar-container-focused {
  transform: translateY(200%);
}

.floatingSearchBar-container.is-floating {
  transform: translateY(-25%);
  transition: transform .5s ease-in-out;

}
