.TextListStoreItemComp {
  $gap: 10px;

  container-type: inline-size;

  &.TextListStoreItemComp--withBullets {
    ul {
      list-style-type: disc;

      li {
        list-style-type: disc;
        margin-left: 20px;
      }
    }
  }

  ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: $gap;

    li {
      font-family: $mainFont;
      font-size: 13px;
      line-height: 20px;
      font-weight: 500;
      color: $black;
      white-space: pre-wrap;
      word-break: break-word;
      flex: 1;
      min-width: calc(50% - 30px);
    }
  }

  @container (min-width: 320px) {
    ul {
      flex-direction: row;
      flex-wrap: wrap;

      li {
        width: calc(50% - #{$gap / 2});
      }
    }
  }

  @media screen and (min-width: 768px) {
    li {
      font-size: 14px;
      line-height: 24px;
    }
  }
}