  @font-face {
  font-family: Cheddar Jack;
  src: url("https://cdn.cibler.io/fonts/cheddar-jack.woff") format("woff"),
    url("https://cdn.cibler.io/fonts/cheddar-jack.ttf") format("ttf");
}

@font-face {
  font-family: Gotham;
  src: url("https://cdn.cibler.io/fonts/Gotham-Light.woff") format("woff"),
    url("https://cdn.cibler.io/fonts/Gotham-Light.otf") format("otf");
  font-weight: 300;
}
@font-face {
  font-family: Gotham;
  src: url("https://cdn.cibler.io/fonts/Gotham.woff") format("woff"),
    url("https://cdn.cibler.io/fonts/Gotham.otf") format("otf");
  font-weight: 400;
}
@font-face {
  font-family: Gotham;
  src: url("https://cdn.cibler.io/fonts/Gotham-Bold.woff") format("woff"),
    url("https://cdn.cibler.io/fonts/Gotham-Bold.otf") format("otf");
  font-weight: 600;
}
@font-face {
  font-family: Gotham;
  src: url("https://cdn.cibler.io/fonts/Gotham-Black.woff") format("woff"),
    url("https://cdn.cibler.io/fonts/Gotham-Black.otf") format("otf");
  font-weight: 800;
}
@font-face {
  font-family: Gotham;
  src: url("https://cdn.cibler.io/fonts/Gotham-BoldItalic.woff") format("woff"),
    url("https://cdn.cibler.io/fonts/Gotham-BoldItalic.otf") format("otf");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: Gotham;
  src: url("https://cdn.cibler.io/fonts/Gotham-MediumItalic.woff")
      format("woff"),
    url("https://cdn.cibler.io/fonts/Gotham-MediumItalic.otf") format("otf");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: Guess Sans;
  src: url("https://cdn.cibler.io/fonts/GuessSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: Thristy Rough;
  src: url("https://cdn.cibler.io/fonts/ThristyRoughBlack.otf") format("otf");
  font-weight: 900;
}
@font-face {
  font-family: Thristy Rough;
  src: url("https://cdn.cibler.io/fonts/ThristyRoughBold.otf") format("otf");
  font-weight: 700;
}
@font-face {
  font-family: Thristy Rough;
  src: url("https://cdn.cibler.io/fonts/ThristyRoughLight.otf") format("otf");
  font-weight: 300;
}
@font-face {
  font-family: Thristy Rough Shadow;
  src: url("https://cdn.cibler.io/fonts/ThristyRoughBlackShadow.otf") format("otf");
  font-weight: 900;
}
@font-face {
  font-family: Thristy Rough Shadow;
  src: url("https://cdn.cibler.io/fonts/ThristyRoughBoldShadow.otf") format("otf");
  font-weight: 700;
}
@font-face {
  font-family: Thristy Rough Shadow;
  src: url("https://cdn.cibler.io/fonts/ThristyRoughLightShadow.otf") format("otf");
  font-weight: 300;
}
@font-face {
  font-family: Thristy Rough One;
  src: url("https://cdn.cibler.io/fonts/ThristyRoughBlackOne.otf") format("otf");
  font-weight: 900;
}
@font-face {
  font-family: Thristy Rough One;
  src: url("https://cdn.cibler.io/fonts/ThristyRoughBoldOne.otf") format("otf");
  font-weight: 700;
}
@font-face {
  font-family: Thristy Rough One;
  src: url("https://cdn.cibler.io/fonts/ThristyRoughLightOne.otf") format("otf");
  font-weight: 300;
}
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@font-face {
  font-family: Addictive-Regular;
  src: url("../../assets/fonts/Addictive-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

$main: Gotham, Arial, sans-serif;
$mainFont: var(--mainFont, $main);
$title: Arial, sans-serif;
$titleFont: var(--titleFont, $title);
$travelPlanerTitleFont: var(--travelPlanerTitleFont, $title);
$travelPlanerSubtitleFont: var(--travelPlanerSubtitleFont, $title);

h1,
.h1 {
  text-transform: uppercase;
  font-size: 30px;
}

h2,
.h2 {
  font-size: 24px;
}

h3,
.h3 {
  font-size: 18px;
}

h4,
.h4 {
  font-size: 16px;
  font-weight: bold;
  font-family: $mainFont;
}

.category {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
}

p,
.courant {
  font-size: 12px;
}

.courant2 {
  font-weight: bold;
}
