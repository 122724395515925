.page404 {
  .Header {
    background: $client;
  }

  .page {
    height: calc(100vh - 290px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      font-family: $titleFont;
      font-size: 80px;
      line-height: 160px;
      font-weight: bold;
      color: #009989;
    }

    .description {
      font-size: 25px;
    }
  }
}
