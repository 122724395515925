.social-banner {
  padding: 70px 20px;
  display: flex;
  flex-direction: column;
  @media all and (min-width: 768px) {
    flex-direction: row;
    padding: 77px 50px;
    justify-content: space-between;
  }
  @media all and (min-width: 1920px) {
    padding: 5% 10%;
  }

  h2 {
    color: #D0DB5F;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: center;
    padding-bottom: 5px;
    padding-left: 8px;
    text-transform: uppercase;
    @media all and (min-width: 768px) {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.02em;
    }
  }

  h3 {
    color: #21212B;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    padding-bottom: 10px;
    @media all and (min-width: 768px) {
      font-size: 22px;
    }

  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media all and (min-width: 768px) {
      padding: 0 55px
    }
  }

  &__title {
    display: flex;
    padding-top: 20px;
  }

  .picto {
    width: 20px;
    height: 20px;
    @media all and (min-width: 768px) {
      width: 24px;
      height: 24px;
    }
  }

  &__links {
    display: flex;
    padding-bottom: 20px;

    &__picto {
      padding: 10px;
    }
  }
}

.social-banner__images__container {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
}

.social-banner-images {
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;

  &__image {
    border-radius: 4.65px;
    overflow: hidden;
    aspect-ratio: 1 / 1;
  }

  &__picto {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 16px;
    height: 16px;
    z-index: 1;

  }

}
