.LabelsShopV2 {
  $gap: 16px;

  display: flex;
  align-items: center;
  gap: $gap;
  flex-wrap: wrap;

  img {
    width: auto;
    height: 32px;
  }

  svg {
    width: 32px;
    height: 32px;
  }

  @media screen and (min-width: 768px) {
    &__handicaps {
      img {
        height: 32px;
      }
    }

    svg {
      width: 32px;
      height: 32px;
    }
  }
}
