.FavoriteCard {
  height: 100%;

  &:hover {
    cursor: pointer;
  }

  .ShopV2Card {
    img {
      border-radius: 3px;
      overflow: hidden;
    }

    .ShopV2Card__container {
      padding: 12px 0 0 0;
    }
  }

  @media screen and (min-width: 1024px) {
    max-width: 320px;
  }
}
