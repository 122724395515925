.GoogleLoginButton {
  width: 100%;

  .GoogleIcon {
    path {
      stroke: none !important;
    }
  }

  @media screen and (min-width: 768px) {
  }
}