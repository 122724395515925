.AccountPanel {
  $headerHeight: 90px;
  $mobilePadding: ($headerHeight + 20px) 20px 27px 20px;
  $desktopPadding: ($headerHeight + 87px) 50px 80px 50px;
  $mobileGap: 15px;
  $desktopGap: 50px;

  padding: $mobilePadding;
  background: $green-light;
  position: relative;
  width: 100%;

  .AccountPanel__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $mobileGap;

    .AccountPanel__title {
      font-family: $mainFont;
      font-size: 22px;
      line-height: 32px;
      font-weight: 700;
    }

    .AccountPanel__desktop__items {
      display: none;
    }
  }

  @media screen and (min-width: 768px) {
    padding: $desktopPadding;
    max-width: 420px;
    flex: 1;

    .AccountPanel__container {
      gap: $desktopGap;
      position: sticky;
      top: ($headerHeight + 87px);

      .AccountPanel__mobile__items {
        display: none;
      }

      .AccountPanel__desktop__items {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .AccountPanel__desktop__item {
          padding: 11px 15px;
          border-radius: 3px;
          text-decoration: none;

          &.active {
            background: $white;
            pointer-events: none;
          }

          &:hover {
            background: #21212B1A;
          }

          svg {
            min-width: 20px;
            min-height: 20px;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}