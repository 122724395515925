.ImageContent {
  position: relative;
  display: flex;

  img {
    position: sticky;
    top: 0;
    object-fit: contain;
    width: fit-content;
    height: fit-content;
  }

  .HTMLVisualContent {
    position: sticky;
    top: 0;
  }

  @media screen and (min-width: 768px) {
  }
}
