.oAuthButton {
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: $white;
    border: solid 1px $gray500;
    border-radius: 20px;
    padding: 8px 45px;
    text-align: center;
    font-size: 14px;
    position: relative;

    svg {
      position: absolute;
      left: 13px;
      height: 20px;
      width: 20px;
    }

    &:hover {
      background: $gray100;
    }
  }

  .googleOAuth {

  }
}
