.Checkbox {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 10px;

  input {
    opacity: 0;
    position: absolute;
    top: 4px;
    left: 4px;

    &:checked + .Checkbox__box {
      .Checkbox__box__checkmark {
        animation: checkmark 0.2s ease-in-out forwards;

        @keyframes checkmark {
          0% {
            scale: 0;
          }
          100% {
            scale: 1;
          }
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .Checkbox__box {
    cursor: pointer;
    border-radius: 2px;
    background: $white;
    border: 1px solid #21212B33;
    width: 20px;
    min-width: 20px;
    height: 20px;

    .Checkbox__box__checkmark {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 2px;
      scale: 0;
      animation: checkmarkReverse 0.2s ease-in-out forwards;

      @keyframes checkmarkReverse {
        0% {
          scale: 1;
        }
        100% {
          scale: 0;
        }
      }
    }
  }

  label {
    font-size: 11px;
    font-weight: 500;
    line-height: 20px;

    a {
      text-decoration: underline;
    }
  }
}
