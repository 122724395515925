.topPageNotice {
  padding: 8px 16px;
  text-align: center;

  &.desktop {
    padding: 16px 32px;
  }

  a{text-decoration: underline;}
}
