.AuthStack {
  $headerHeight: 90px;
  $pageMobilePadding: 10px 0 70px 0;
  $pageDesktopPadding: ($headerHeight + 80px) 50px 120px 84px;

  display: flex;
  flex-direction: column;

  .AuthStack__wrapper {
    position: relative;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;

    .AuthStack__page {
      flex: 1;
      width: 100%;
      overflow: hidden;
      padding: $pageMobilePadding;
    }
  }

  @media screen and (min-width: 768px) {
    .AuthStack__wrapper {
      flex-direction: row;
      align-items: stretch;

      .AuthStack__page {
        padding: $pageDesktopPadding;
      }
    }
  }
}