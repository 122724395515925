.bannerWithTextImage {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 48px);
  border-radius: 10px;
  padding: 20px 30px;
  background: #0F1F6B;
  cursor: pointer;

  &__content {
    display: flex;
    align-items: center;
    font-size: 30px;
    line-height: 1.5;
    color: #fff;
    font-weight: 400;
    margin-bottom: 16px;
    width: 70%;
    padding:0 5%;

    em {
      font-style: normal;
      color: #fff;
      font-family: 'Ubuntu', Arial, sans-serif;
      z-index: 1;

      span {

        position: relative;
        z-index: 1;

        &::after {
          content: "";
          position: absolute;
          bottom: -8px;
          left: 0;
          width: 80%;
          height: 60%;
          background: #D63420;
          z-index: -1;
          transition: all 0.3s ease-in-out;
        }

        &:hover {
          &::after {
            right: 0;
            width: 100%;
          }
        }
      }
    }
  }

  &__image {
    width: 30%;
    object-fit: cover;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      object-fit: cover;
      width: 240px;
      height: 170px;
      border-radius: 10px;
      transition: opacity 0.5s ease-in-out;
    }

    img:hover {
      opacity: 0.8;
    }
  }
}

