.Popover {
  width: fit-content;

  .Popover__button {
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      circle {
        fill: currentColor;
      }
    }
  }
}

.Popover__container {
  z-index: 1000;
  width: 100vw;
  max-width: 320px;
  overflow: hidden !important;
  border-radius: 3px;
  background: $white;
  box-shadow: 0 2px 3px 0 #00000026;
}
