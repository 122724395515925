.AccountPage {
  $mobilePadding: 0 20px;
  $desktopPadding: 10px 0 0 0;

  flex: 1;
  width: 100%;
  padding: $mobilePadding;

  .Form {
    gap: 30px;
  }

  @media screen and (min-width: 768px) {
    padding: $desktopPadding;

    .Form {
      align-items: flex-start;
      gap: 40px;

      .Form__fields {
        .Form__fields {
          max-width: 660px;
        }
      }

      .Form__submitContainer {
        width: auto;

        .Form__submit {
          width: 320px;
        }
      }
    }
  }
}