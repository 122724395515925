.GuestStack {
  display: flex;
  flex-direction: column;

  .GuestStack__wrapper {
    position: relative;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;

    .GuestStack__wrapper__background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .GuestStack__wrapper__page-container {
      z-index: 1;
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .GuestStack__wrapper__page-container__page {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: $white;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .GuestStack__wrapper {
      .GuestStack__wrapper__page-container {
        padding: 20px;
        max-width: 660px;

        .GuestStack__wrapper__page-container__page {
          flex: none;
          border-radius: 3px;
          box-shadow: 0 2px 3px 0 #00000026;
        }
      }
    }
  }
}
