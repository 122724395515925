.full-screen-menu {

  @media all and (min-width: 768px) {
    display: none;
  }
  
  .menu-content {
    // padding: 80px 0 0 0;
    margin: calc(20% + 100px) 0 0 5%;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    color: $black;

    .menu-item a {
      width: 100%;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      color: black;
      gap: 10px;
      transition: all 0.3s;

      font-family: $mainFont;
      font-size: 1.3rem;
      line-height: 20px;
      font-weight: 700;

      cursor: pointer;

      &::after {
        content: '';
        display: block;
        width: 0;
        height: 2px;
        background: white;
        transition: all 0.3s;
      }

      &:hover {
        &::after {
          width: 10%;
        }
      }

      svg {
        width: 40px;
        height: 40px;

        ellipse,
        path {
          stroke: black;
        }
      }
    }
  }
}
